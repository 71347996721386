import React, { useState, useEffect, useRef } from 'react';
import {Utils} from './bpdbcrud.js';
import audioBufferToWav from 'audiobuffer-to-wav';

function PlayBack({text2Voice, synthesizing, onClose})
{
    const [speed, setSpeed] = useState(1);
    const audioRef = useRef();
    const timeControl = (delta) => {
        const audio = audioRef.current;
        if (audio && audio.src) {
            audio.currentTime += delta;
        }
    };
    const playAtSpeed = (speed) => {
        const audio = audioRef.current;
        audio.volume = 1;
        audio.playbackRate = speed;
        setSpeed(speed);
        audio.play().catch((error) => {
            console.log(error);
            Utils.message('播放语音错误', '此段文字无法转换成语音');
        });
    };

    useEffect(() => {
        if (text2Voice?.voice) {
            const audio = audioRef.current;
            audio.src = text2Voice.voice;
            audio.addEventListener('error', (e) => {
                console.log(e);
                Utils.message('播放语音错误', '此段文字无法转换成语音');
            }, { once: true });
            playAtSpeed(1);
        }
    }, [text2Voice]);

    const displayPlayback = () => {
        if (!text2Voice?.content) {
            return null;
        }

        const speedButtonClass = (desiredSpeed) => {
            const audio = audioRef.current;
            return `btn btn-sm flex-grow-1
                btn-${desiredSpeed === speed ? 'primary' : 'secondary'}`;
        };

        return <div className="top-panel">
            <div className="show-playback">
                { synthesizing ?
                    <div className="synthesizing">
                        <div className="spinning-circle">
                            <span className="icon-spinner10"/>
                        </div>
                    </div>
                    : null
                }
                <div className="panel-close">
                    <span
                        className="icon-clear"
                        onClick={() => {
                            onClose();
                        }}
                    />
                </div>
                <audio
                    ref={audioRef}
                    controls={true}
                />
                <div className="time-control">
                    <button onClick={() => timeControl(-30)} >
                        <span className="icon-replay_30" />
                    </button>
                    <button onClick={() => timeControl(-10)} >
                        <span className="icon-replay_10" />
                    </button>
                    <button onClick={() => timeControl(-5)} >
                        <span className="icon-replay_5" />
                    </button>
                    <button onClick={() => timeControl(5)} >
                        <span className="icon-forward_5" />
                    </button>
                    <button onClick={() => timeControl(10)} >
                        <span className="icon-forward_10" />
                    </button>
                    <button onClick={() => timeControl(30)} >
                        <span className="icon-forward_30" />
                    </button>
                </div>
                <div className="speed-control">
                    <button
                        className={speedButtonClass(.7)}
                        onClick={() => { playAtSpeed(.7); }}
                    >慢速</button>
                    <button
                        className={speedButtonClass(1)}
                        onClick={() => { playAtSpeed(1); }}
                    >原始语速</button>
                    <button
                        className={speedButtonClass(1.5)}
                        onClick={() => { playAtSpeed(1.5); }}
                    >快速</button>
                </div>
            </div>
        </div>;
    };

    return displayPlayback();
}

async function concatAudioBuffers(arrayBuffers)
{
    const AudioContext = window.AudioContext || window.webkitAudioContext
        || window.mozAudioContext;
    const context = new AudioContext();

    const buffers = [];
    for (const ab of arrayBuffers) {
        try {
            buffers.push(await context.decodeAudioData(ab));
        } catch (error) {
            console.log(error);
        }
    }
    if (buffers.length === 0) {
        return;
    }

    const merged = context.createBuffer(
        Math.max(...buffers.map((buffer) => buffer.numberOfChannels)),
        buffers.map((buffer) => buffer.length).reduce((a, b) => a + b, 0),
        context.sampleRate
    );
    let offset = 0;
    for (const buffer of buffers) {
        for (let cn = 0; cn < buffer.numberOfChannels; cn++) {
            merged.getChannelData(cn)
                .set(buffer.getChannelData(cn), offset);
        }
        offset += buffer.length;
    }
    const blob = new Blob(
        [audioBufferToWav(merged, {float32: true})],
        { type: "audio/mp3" }
    );
    return blob;
}

export { PlayBack, concatAudioBuffers };
