import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App.js';
import './bpdbcrud.css';
//import { webm, mp4 } from './NoSleepSource.js';

const rootElement = document.getElementById('root');
ReactDOM.createRoot(rootElement).render(<App />);

    /* Not available for iOS prior to 16.4
const enable = async () => {
    console.warn('prevent sleeping');
    try {
        const wakeLock = await navigator.wakeLock.request("screen");
    } catch (err) {
        // the wake lock request fails - usually system related,
        // such being low on battery
        console.log(`${err.name}, ${err.message}`);
    }
};
const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
        enable();
    }
};

document.addEventListener("visibilitychange", handleVisibilityChange);
document.addEventListener("fullscreenchange", handleVisibilityChange);
    */

   /*
function noSleep()
{
    // Create the root video element
    const video = document.createElement('video');
    //document.body.appendChild(video);
    video.setAttribute('playsinline', '');
    // Add some styles if needed
    //video.setAttribute('style', 'display: none;');
    video.addEventListener('loadedmetadata', () => {
        //Utils.tipOnTop('meta loaded' + video.duration);
        if (video.duration <= 1) {
            // webm source
            video.setAttribute('loop', '');
        } else {
            // mp4 source
            video.addEventListener('timeupdate', () => {
                if (video.currentTime > 0.5) {
                    video.currentTime = 0.1;
                }
            });
        }
    });

    // A helper to add sources to video
    function addSourceToVideo(element, type, dataURI) {
        const source = document.createElement('source');
        source.src = dataURI;
        source.type = 'video/' + type;
        element.appendChild(source);
    }

    // Add Fake sourced
    addSourceToVideo(video,'webm', webm);
    addSourceToVideo(video, 'mp4', mp4);

    // Start playing video after any user interaction.
    // NOTE: Running video.play() handler without a user action may be blocked by browser.
    const playFn = () => {
        console.warn('prevent sleeping');
        //Utils.tipOnTop('prevent sleeping');
        video.play().catch((err) => {
            Utils.tipOnTop(JSON.stringify(err));
            console.log(err);
        });
        document.body.removeEventListener('touchend', playFn);
    };
    document.body.addEventListener('touchend', playFn);
}
*/

