import React, { useState, useEffect, useRef } from 'react';
import logo from './icon.png';
import TalkyPanel from './TalkyPanel.js';
import { accessCode } from './bpdbcrud.js';

function Nav({currentPanel, setCurrentPanel})
{
    const [showTalky, setShowTalky] = useState(false);
    const [showTertiary, setShowTertiary] = useState(false);
    const historyTalkyMessages = useRef();
    const stateCache = useRef();

    const logout = () => {
        document.cookie =
            'accesscode=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        localStorage.removeItem('accesscode');
        window.location.reload();
    };

    useEffect(() => {
        historyTalkyMessages.current = [[]];
        stateCache.current = {
            lastTimeOfBalance: Date.now()
        };
    }, []);

    const saveToHistory = (talkyMessages) => {
        historyTalkyMessages.current = talkyMessages;
    };

    function getBalance()
    {
        console.log('get balance');
        stateCache.current.lastTimeOfBalance = Date.now();
        accessCode.checkAccessCode();
    }

    return <>
        <div className='nav-header'>
            <img src={logo} alt='' className='nav-logo' />
            <div className='nav-buttons'>
                <button
                    className={`switch lighting-button
                        ${currentPanel === 'ChatGPT' ? ' on' : ''}`}
                    onClick={() => {
                        setShowTertiary(false);
                        setShowTalky(false);
                        setCurrentPanel('ChatGPT');
                    }}
                >
                    <span className="icon-subject"/>问答
                </button>
                <button
                    className={`switch lighting-button
                        ${showTalky ? ' on' : ''}`}
                    onClick={() => {
                        setShowTalky(!showTalky);
                        setShowTertiary(false);
                    }}
                >
                    <span className="icon-record_voice_over"/>对话
                </button>
                <button
                    className={`switch lighting-button
                        ${currentPanel === 'Painter' ? ' on' : ''}`}
                    onClick={() => {
                        setShowTertiary(false);
                        setShowTalky(false);
                        setCurrentPanel('Painter');
                    }}
                >
                    <span className="icon-color_lens"/>画画
                </button>
            </div>
            <button
                className={`tertiary lighting-button
                    ${showTertiary ? ' on' : ''}`}
                onClick={() => {
                    if (!showTertiary) {
                        const ltob = stateCache.current.lastTimeOfBalance;
                        if (Date.now() - ltob > 10000) {
                            getBalance();
                        }
                    }
                    setShowTertiary(!showTertiary);
                    setShowTalky(false);
                }}
            >
                <div className="dehaze"></div>
                <div className="dehaze"></div>
                <div className="dehaze"></div>
            </button>
            <div
                className={`tertiary-panel${showTertiary ? '' : ' d-none'}`}
            >
                <div className="personal-info">
                    <div className="phone">
                        <span>帐号</span>
                        <span>{accessCode.phone}</span>
                    </div>
                    <div className="account">
                        余额{accessCode.balance}元
                    </div>
                    <div
                        className="sign-out-button"
                        onClick={logout}
                    >
                        <span className="icon-sign-out" />
                    </div>
                </div>
            </div>
        </div>
        { showTalky ?
            <TalkyPanel
                historyTalkyMessages={historyTalkyMessages.current}
                saveToHistory={saveToHistory}
                closeTalky={() => setShowTalky(false)}
            /> : null
        }
    </>;
}

export default Nav;
