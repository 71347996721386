import React, { useState, useEffect, useRef } from 'react';
import {Utils} from './bpdbcrud.js';
import katex from 'katex';
//import renderMathInElement from 'katex/dist/contrib/auto-render.mjs';
import 'katex/dist/katex.min.css';
import { Marked } from 'marked';
import { markedHighlight } from "marked-highlight";
import mermaid from 'mermaid';
import * as DOMPurify from 'dompurify';
/*
import 'https://polyfill.io/v3/polyfill.min.js?features=es6';
import 'mathjax/es5/tex-chtml.js';
const MathJax = {
    tex: {
        inlineMath: [['\\(', '\\)']],
        displayMath: [['\\[', '\\]']],
    }
};
*/

function Typewriter({
    text, prevText, speed = 10, blinking = true, role
})
{
    const [displayedText, setDisplayedText] = useState([]);
    const [displayedObject, setDisplayedObject] = useState([]);
    const [originalText, setOriginalText] = useState('');

    const [bling, setBling] = useState(blinking);

    const interval = useRef();

    const BLINKING_CURSOR = <span key="blinking" className="blinking-cursor">
    </span>;

    function newline(str, last)
    {
        const lines = str?.split(/([\n\r]+)/);
        const arr = [];
        lines?.forEach((l, j) =>  {
            arr.push(
                /[\n\r]+/.test(l) ? <br key={`br${last - j}`} /> : l
            );
        });
        return arr;
    }

    const CODE_COPY = '<span class="icon-content_copy"></span>';
    const MERMAID_TEXT_SWITCHER = '<span class="icon-picture"></span>';
    const CODE_BLOCK_REGEX = /```(.*?)\n([\s\S]*?)```\n/g;
    function quoteCodeBlock(content)
    {
        if (bling) {
            const results = [];

            let match;
            /**
             * Each time RegEx.exec() executes, it will start from the last
             * end of the previous matching.
             */
            /**
             * Reset lastIndex.
             */
            CODE_BLOCK_REGEX.lastIndex = 0;
            while ((match = CODE_BLOCK_REGEX.exec(content)) !== null) {
                const start = match.index;
                const end = start + match[0].length;
                const codes = match[2];
                results.push({ start, end, codes });
            }

            let last = 0;
            const display = [];
            for (let i = 0; i < results.length; i++) {
                const r = results[i];
                display.push(...newline(
                    content.substring(last, r.start), last
                ));
                display.push(<div key={i}
                    dangerouslySetInnerHTML={{__html: codeHeader('', '')}}
                />);
                display.push(
                    <pre key={`code${i}`}><code>{r.codes}</code></pre>
                );
                last = r.end;
            }
            const nls = newline(content.substring(last), last)
            if (nls.length && typeof nls.at(-1) !== 'string') {
                nls.splice(-1, 0, BLINKING_CURSOR);
            } else {
                nls.push(BLINKING_CURSOR);
            }
            display.push(...nls);
            setDisplayedText([]);
            setDisplayedObject(display);
        } else {
            if (role === 'user') {
                content = content.replaceAll('<', '&lt;');
                setDisplayedObject([]);
                setDisplayedText(content);
                return;
            }
            markdownLatex(convertLatex(content));
            /*
            const latexElement = document.createElement('div');
            latexElement.innerText = content;
            renderMathInElement(latexElement, {
                delimiters: [{
                    left: "$$", right: "$$", display: true
                }, {
                    left: "\\(", right: "\\)", display: false
                }, {
                    left: "\\begin{equation}",
                    right: "\\end{equation}",
                    display: true
                }, {
                    left: "\\begin{align}",
                    right: "\\end{align}",
                    display: true
                }, {
                    left: "\\begin{alignat}",
                    right: "\\end{alignat}",
                    display: true
                }, {
                    left: "\\begin{gather}",
                    right: "\\end{gather}",
                    display: true
                }, {
                    left: "\\begin{CD}",
                    right: "\\end{CD}",
                    display: true
                }, {
                    left: '\\[\\s*', right: '\\s*\\]', display: true
                }, {
                    left: "$", right: "$", display: false
                }]
            });
            console.log(latexElement.innerHTML);
            markdownLatex(latexElement.innerHTML.replaceAll(/<br>/g, '\n').replaceAll(/&lt;/g, '<').replaceAll(/&gt;/g, '>'));
            */

           /*
            const output = document.createElement('div');
            MathJax.typesetPromise(content, {}).then((node) => {
                //
                //  The promise returns the typeset node, which we add to the output
                //  Then update the document to include the adjusted CSS for the
                //    content of the new equation.
                //
                output.appendChild(node);
                MathJax.startup.document.clear();
                MathJax.startup.document.updateDocument();
                console.log(node);
            }).catch(function (err) {
                console.log(err);
                //
                //  If there was an error, put the message into the output instead
                //
                output.appendChild(document.createElement('pre')).appendChild(document.createTextNode(err.message));
            });
            */

        }
    }

    function convertLatex(text)
    {
        const latexRegex =
            ///(\S?)(?:\\\(([\s\S]*?)\\\)|\\\[([\s\S]*?)\\\])(\S?)/gm;
            /(?:\\\(([\s\S]*?)\\\)|\\\[([\s\S]*?)\\\])/gm;
        function replaceFunc(match, p1, p2)
        {
            const p = p1 || p2;
            if (!p) {
                return match;
            }
            try {
                return katex.renderToString(p, {
                    /**
                     * When using DOMPurify.santinize(), output should be set
                     * to 'html'. If setting to 'mathml', the original latex
                     * math string will be displayed aside the mathml.
                     * Using html seems better.
                     */
                    output: 'html',
                    throwOnError: true,
                    displayMode: p2 ? true : false,
                    strict: false
                });
            } catch (error) {
                //console.error(error);
                return match;
            }
        }

        function processCodeInText(substr)
        {
            let subMatch;
            let slast = 0;
            while ((subMatch = codeRegex.exec(substr)) !== null) {
                const sstart = subMatch.index;
                const send = sstart + subMatch[0].length;
                const scode = subMatch[2];
                if (sstart > 0) {
                    results.push({text: substr.substring(slast, sstart)});
                }
                results.push({code: subMatch[1]});
                slast = send;
            }
            if (slast < substr.length) {
                results.push({
                    text: substr.substring(slast, substr.length)
                });
            }
        }

        const codeRegex = /(`[\s\S]*?`)/gm;

        /**
         * Don't convert code blocks.
         */
        const results = [];
        let match;
        let last = 0;
        /**
         * Reset lastIndex.
         */
        CODE_BLOCK_REGEX.lastIndex = 0;
        while ((match = CODE_BLOCK_REGEX.exec(text)) !== null) {
            const start = match.index;
            const end = start + match[0].length;
            const codes = match[2];
            if (start > 0) {
                const substr = text.substring(last, start).trim();
                processCodeInText(substr);
            }
            results.push({
                code: '\n```' + match[1] + '\n' + match[2] + '\n```\n'
            });
            last = end;
        }
        if (last < text.length) {
            processCodeInText(text.substring(last, text.length));
        }
        //console.log(results);

        const latexParts = [];
        for (const r of results) {
            if (r.code) {
                latexParts.push(r.code);
                continue;
            }
            latexParts.push(r.text.replace(latexRegex, replaceFunc));
        }

        return latexParts.join('');
    }

    function codeHeader(name, match)
    {
        return `<div class="code-header">
            <div>
                <span class="icon-code"></span>
                <span>
                    ${name || ''}
                </span>
            </div>
            <button class="code-copy-button">
                ${CODE_COPY}
            </button>
        </div>${match || ''}`;
    }

    function mermaidHeader(name, match)
    {
        return `<div class="mermaid-header">
            <div>
                <span class="icon-code"></span>
                <span>
                    ${name || ''}
                </span>
            </div>
            <button class="mermaid-text-button">
                ${MERMAID_TEXT_SWITCHER}
            </button>
            <button class="mermaid-copy-button">
                ${CODE_COPY}
            </button>
        </div>${match || ''}`;
    }

    function markdownLatex(latex)
    {
        const marked = new Marked(
            markedHighlight({
                highlight(code, lang, info) {
                    if (lang === 'mermaid') {
                        return `<div class="mermaid">${code}</div>`
                            + `<div class="mermaid-code">${code}</div>`;
                    }
                    return code;
                }
            })
        );
        const markdown = DOMPurify.sanitize(marked.parse(latex));
        const out = markdown.replace(
            /<pre><code([^>]*)>/g,
            (match, lang) => {
                const name = lang.match(/class="(\S+)\s*.*"/);
                const ln = name?.[1]?.split('-', 2)?.[1];
                if (ln === 'mermaid') {
                    return mermaidHeader(
                        ln,
                        match.replace(`<pre>`, `<pre class="mermaid-pre">`)
                    );
                } else {
                    return codeHeader(ln, match);
                }
            }
        );
        setDisplayedObject([]);
        setDisplayedText(out);
    }

    useEffect(() => {
        if (blinking || !finalHtml.current) {
            return;
        }

        function findCodeBlock(p)
        {
            while (p && p.nextElementSibling?.nodeName !== 'PRE') {
                p = p.parentNode;
            }
            if (!p) {
                return;
            }
            return p.nextElementSibling;
        }

        const copyButtons = finalHtml.current.querySelectorAll(
            'button[class*="code-copy-button"]'
        );
        copyButtons.forEach((bn) => {
            bn.onclick = (e) => {
                const codeBlock = findCodeBlock(e.target.parentNode)
                if (!codeBlock) {
                    return;
                }
                bn.innerHTML = '<span class="icon-check"></span>';
                Utils.copyToClipboard(codeBlock.textContent, () => {
                    setTimeout(() => {
                        bn.innerHTML = CODE_COPY;
                    }, 10000);
                });
            };
        });
        const mermaidCopyButtons = finalHtml.current.querySelectorAll(
            'button[class*="mermaid-copy-button"]'
        );
        mermaidCopyButtons.forEach((bn) => {
            bn.onclick = (e) => {
                const cb = findCodeBlock(e.target.parentNode)
                if (!cb) {
                    return;
                }
                const codeBlock = cb.querySelector(
                    'div[class="mermaid-code"]'
                );
                bn.innerHTML = '<span class="icon-check"></span>';
                Utils.copyToClipboard(codeBlock.textContent, () => {
                    setTimeout(() => {
                        bn.innerHTML = CODE_COPY;
                    }, 10000);
                });
            };
        });
        const mermaidTextButtons = finalHtml.current.querySelectorAll(
            'button[class*="mermaid-text-button"]'
        );
        mermaidTextButtons.forEach((bn) => {
            bn.onclick = (e) => {
                const cb = findCodeBlock(e.target.parentNode)
                if (!cb) {
                    return;
                }
                const codeBlock = cb.querySelector(
                    'div[class="mermaid-code"]'
                );
                const svgBlock = cb.querySelector(
                    'div[class="mermaid"]'
                );
                if (!svgBlock || !codeBlock) {
                    return;
                }
                const showText = bn.getAttribute('data-showtext');
                if (showText) {
                    svgBlock.style.display = '';
                    codeBlock.style.display = '';
                    bn.removeAttribute('data-showtext');
                } else {
                    svgBlock.style.display = 'none';
                    codeBlock.style.display = 'block';
                    bn.setAttribute('data-showtext', 'yes');
                }
            };
        });
        /**
         * Must call initialize, or multiple mermaid SVG images might be
         * overlapped.
         */
        mermaid.initialize({startOnLoad: false});
        mermaid.run();
    });

    const clearTimer = () => {
        if (interval.current) {
            clearInterval(interval.current);
            interval.current = false;
        }
    };

    useEffect(() => {
        return clearTimer;
    }, []);

    useEffect(() => {
        if (speed < 0) {
            setBling(blinking);
        }
    }, [blinking]);

    useEffect(() => {
        if (speed < 0) {
            quoteCodeBlock(text);
        }
    }, [bling]);

    useEffect(() => {
        let currentIndex = 0;

        const output = () => {
            if (currentIndex >= text.length) {
                clearTimer();
                setBling(blinking);
                return;
            }

            const currentChar = text[currentIndex];
            setOriginalText((prevOriginalText) => {
                //console.log(prevOriginalText);
                const n = prevOriginalText + currentChar;
                quoteCodeBlock(n);
                return n;
            });

            currentIndex++;
        };

        function wholeOutput(out)
        {
            const content = out.replaceAll('<', '&lt;');
            setDisplayedObject([]);
            setDisplayedText(content);
            /*
            const slices = out.split(/(?:\r|\n)+/);
            const display = [];
            for (let i = 0; i < slices.length; i++) {
            //slices.forEach((c, i) => {
                const c = slices[i];
                display.push(<p key={i}>{c}</p>);
            //});
            }
            setDisplayedObject(display);
            */
        }

        if (speed > 0) {
            clearTimer();
            setOriginalText(() => {
                interval.current = setInterval(output , speed);
                //console.log(interval.current);
                return prevText;
            });
        } else if (speed === 0) {
            wholeOutput(text);
        } else {
            quoteCodeBlock(text);
        }

        return clearTimer;
    }, [prevText, text, speed]);

    const finalHtml = useRef();
    return <>
        {
            displayedObject.length ? displayedObject : null
        }
        {
            displayedText.length ? <div
                ref={finalHtml}
                style={{whiteSpace: role === 'user' ? 'preserve' : 'normal'}}
                dangerouslySetInnerHTML={{__html: displayedText}}
            /> : null
        }
    </>;
}

export default Typewriter;

