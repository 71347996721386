import React, { useState, useEffect } from "react";

function Modal({event, operations, onClose})
{
    const [modalStyle, setModalStyle] = useState({ display: "none" });

    const popup = (event) => {
        const box = event.target.getBoundingClientRect();
        const x = box.x;
        const y = window.innerHeight - box.y + 2;

        setModalStyle({
            display: "flex",
            position: "absolute",
            left: `${x}px`,
            bottom: `${y}px`,
            backgroundColor: "#fefefe",
            padding: "5px",
            border: "1px solid rgba(136, 136, 136, 0.58)",
            borderRadius: '5px',
            gap: '5px',
            flexDirection: 'column',
            zIndex: 202
        });
    };

    const cancel = (e) => {
        /**
         * If no event.stopPropagation() is called inside the trigger's event
         * handler such as a button's click handler that is to pop up this
         * modal, cancel will be executed at the same time when the button is
         * clicked.
         */
        if (e.target !== event.target) {
            onClose();
        }
    };

    useEffect(() => {
        popup(event);
        window.addEventListener('click', cancel);
        return () => {
            window.removeEventListener('click', cancel);
            onClose();
        };
    }, []);

    return (
        <div style={modalStyle}>
            {
                operations.map((op, key) => {
                    return <button
                        key={key}
                        className="btn btn-sm btn-outline-secondary"
                        onClick={op.click}
                    >{op.element}</button>
                })
            }
        </div>
    );
}

export default Modal;

