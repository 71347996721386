import React, { useEffect, useState, useRef } from 'react';
import { Utils, JSONEditor, query } from './bpdbcrud.js';
import './Instruction.css';

let map = null;
function Instruction()
{
    async function getInstructions(onDone)
    {
        if (map) {
            onDone(map);
            return;
        }
        const criteria = [{
            n: 'created_time',
            s: 'int',
            f: 1,
            t: ''
        }];
        const list = await query(
            'instruction',
            criteria,
            '',
            1,
            0,
            '*'
        );
        if (list.error) {
            Utils.tipOnTop('获取指令失败：' + list.code);
            return;
        }
        map = new Map();
        list.extra.list.forEach((instr) => {
            try {
                map.set(instr.id, {
                    id: instr.id,
                    name: instr.field.name[0],
                    config: JSON.parse(instr.field.config[0]),
                    meta: instr.meta
                });
            } catch (e) {
                Utils.tipOnTop(
                    `JSON error of instruction ${instr.id}:
                    ${instr.field.config[0]}
                    ${e}
                `);
            }
        });

        onDone(map);
    }

    function get(ikey)
    {
        return map?.get(ikey);
    }

    function format(instr)
    {
        return <>
            <div className="instruction-caption">
                当前指令：{instr?.name}
            </div>
            <JSONEditor json={instr.config} editable={false} />
        </>;
    }

    function list(instructionMap, current, onClick)
    {
        if (!instructionMap) {
            return null;
        }
        return [...instructionMap.entries()].map(([id, instr], index) => {
            return <div className="instruction-list-item" key={index}>
                <button className={`instruction-selector
                    ${current === id ? 'active' : ''}`}
                    onClick={() => onClick(instr)}
                >
                    {instr?.name}
                </button>
            </div>;
        });
    }

    function reloadInstructions(onDone)
    {
        map = null;
        getInstructions(onDone);
    }

    return {
        reloadInstructions,
        getInstructions,
        list,
        format,
        get
    };
}

export default Instruction();

