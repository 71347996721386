import './Main.css';
import React, { useState, useEffect, useRef } from 'react';
import CompletionComponent from './CompletionComponent.js';
import {Utils, WebSocket, Spinner, accessCode} from './bpdbcrud.js';
import PromptInputComponent from './PromptInput.js';

function ChatGPT({historyResult, updateHistory, onInvalidSession})
{
    const SPEED = 50;

    const [isLoading, setIsLoading] = useState(false);
    const [visionImages, setVisionImages] = useState([]);
    const [fetchSocket, setFetchSocket] = useState(false);
    const [historyList, setHistoryList] = useState(historyResult);
    const [quotedChats, setQuotedChats] = useState([]);
    const [showSearch, setShowSearch] = useState(false);
    const [condition, setCondition] = useState('');

    const renderHistoryItem = (item) => {
        if (item.html) {
            item.html = {...item.html};
        } else {
            item.html = {};
        }
        item.toggleSelection = () => {
            item.selected = !item.selected;
            item.html.member = {...item};
            setQuotedChats((prev) => {
                const qc = [];
                historyList.map((h, i) => {
                    drawQuoteButton(qc, h, i);
                })
                return qc;
            });
        };
        item.html.member = item;
    };

    const addUserInput = (userInput, visionImages) => {
        const member = {
            role: 'user',
            content: userInput,
            selected: false
        };
        member.html = {
            speed: 0,
            isLoading: false,
            completion: userInput,
            visionImages: visionImages,
        };
        renderHistoryItem(member);
        historyList.push(member);
        return member;
    };

    function stopFetching()
    {
        if (fetchSocket) {
            fetchSocket.disconnect();
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!historyResult) {
            searchHistory();
        }
    }, []);

    useEffect(() => {
        if (historyList) {
            historyList.forEach(renderHistoryItem);
        }
    }, [historyList]);

    const submitPrompt = (submission) => {
        if (submission) {
            fetchCompletion(submission);
        }
        return true;
    };

    const fetchCompletion = async (userInput) => {
        const newPrompt = addUserInput(userInput, visionImages);
        newPrompt.visionImages = visionImages;
        setVisionImages([]);

        let stop = false;
        setIsLoading(true);

        const messages = [];
        historyList.forEach((h, i) => {
            if (h.selected) {
                messages.push({role: h.role, content: h.content});
                h.toggleSelection();
            }
        });
        messages.push({role: 'user', content: userInput});

        /**
         * Append a placeholder for the new completion.
         */
        const newCompletion = {
            role: 'assistant',
            content: '',
            selected: false,
            html: {
                isLoading: true
            }
        };
        renderHistoryItem(newCompletion);
        historyList.push(newCompletion);

        const bulkResponse = (chunk) => {
            const lci = historyList.length - 1;
            const item = historyList[lci];
            //console.log('Received data:', chunk);
            stop = stop || (chunk?.finish_reason === 'stop');
            const prev = item.content || '';
            historyList[lci] = {
                id: newCompletion.id,
                role: 'assistant',
                content: prev + chunk.content,
                selected: item.selected,
                html: {
                    speed: SPEED,
                    isLoading: false,
                    prevText: prev,
                    completion: chunk.content,
                    blinking: !stop,
                }
            };
            renderHistoryItem(historyList[lci]);
            setHistoryList([...historyList]);
            if (stop) {
                socket.close();
            }
        };

        const convertStream = async (chunk) => {
            const lci = historyList.length - 1;
            const item = historyList[lci];
            let typedin = item.content || '';
            typedin += (chunk?.c || '');
            stop = stop || (chunk?.finish_reason === 'stop');

            historyList[lci] = {
                id: newCompletion.id,
                role: 'assistant',
                content: typedin,
                selected: item.selected,
                html: {
                    speed: -1,
                    isLoading: false,
                    completion: typedin,
                    blinking: !stop,
                }
            };
            renderHistoryItem(historyList[lci]);
            setHistoryList([...historyList]);
        };

        const socket = WebSocket.create(
            /**
             * On connect.
             */
            () => {
                setFetchSocket(socket);
                /**
                 * Must set the completion handlers before sending requests.
                 */
                socket.on('bulk', (chunk) => {
                    bulkResponse(chunk);
                });
                socket.on('granule', (chunk) => {
                    convertStream(chunk);
                });
                /**
                 * Get the history id.
                 */
                socket.on('history-id', (id) => {
                    newPrompt.id = id;
                    newCompletion.id = id;
                });
                /**
                 * Or the completion message from the server might arrive
                 * before its handler is ready.
                 */
                socket.emit(
                    'messages',
                    accessCode.readAccessCode(),
                    messages,
                    newPrompt.visionImages
                );
            },
            /**
             * On disconnect.
             */
            () => {
                setIsLoading(false);
                //setSubmission(false);
                console.log('disconnected', stop);
                if (!stop) {
                    const lci = historyList.length - 1;
                    const item = historyList[lci];
                    const saved = item.content || '';
                    historyList[lci] = {
                        role: 'assistant',
                        content: saved,
                        selected: item.selected,
                        html: {
                            speed: -1,
                            isLoading: false,
                            isError: true,
                            prevText: saved,
                            completion: '[未完成]',
                            blinking: false,
                        }
                    };
                    renderHistoryItem(historyList[lci]);
                }
                setFetchSocket(false);
            },
            /**
             * On connect error.
             */
            (err) => {
                setIsLoading(false);
                //setSubmission(false);
                showError(historyList, err);
            },
            /**
             * On error.
             */
            (err) => {
                //setSubmission(false);
                if (err.error === 'invalidsession') {
                    onInvalidSession();
                } else if (err.error === 'insufficient') {
                    historyList.splice(-2);
                    setHistoryList(historyList);
                } else {
                    stop = true;
                    showError(historyList, err);
                }
            }
        );

    };

    const searchHistory = () => {
        const socket = WebSocket.create(
            () => {
                Utils.showLoading();
                socket.on('history', (history) => {
                    const loadedHistory = [];
                    if (history.error) {
                        Utils.hideLoading();
                        Utils.tipOnTop('检索出错');
                        return;
                    }
                    for (const h of history.history.reverse()) {
                        const prompt = {
                            id: h.id,
                            role: 'user',
                            content: h.field.prompt[0],
                            audio: parseInt(h.field.prompt_audio?.[0]) || '',
                            images: h.field.prompt_images?.[0],
                            time: h.field.time?.[0],
                            selected: false
                        };
                        prompt.html = {
                            css: 'user',
                            selected: false,
                            speed: -1,
                            isLoading: false,
                            prevText: '',
                            completion: h.field.prompt[0],
                            blinking: false
                        };
                        renderHistoryItem(prompt);
                        loadedHistory.push(prompt);

                        const completion = {
                            id: h.id,
                            role: 'assistant',
                            content: h.field.completion[0],
                            audio: parseInt(h.field.completion_audio?.[0]),
                            selected: false
                        };
                        completion.html = {
                            speed: -1,
                            isLoading: false,
                            prevText: '',
                            completion: h.field.completion[0],
                            blinking: false
                        };
                        renderHistoryItem(completion);
                        loadedHistory.push(completion);
                    }
                    setHistoryList(loadedHistory);
                    updateHistory(loadedHistory);
                    Utils.hideLoading();
                    socket.disconnect();
                });
                socket.emit('history', accessCode.readAccessCode(), condition);
            },
            () => {
            },
            /**
             * On connect error.
             */
            (err) => {
                console.log(err);
                Utils.tipOnTop(JSON.stringify(err));
            },
            /**
             * On error.
             */
            (err) => {
                if (err.error === 'invalidsession') {
                    onInvalidSession();
                } else if (err.error === 'insufficient') {
                    Utils.tipOnTop(JSON.stringify(err));
                }
                Utils.hideLoading();
            }
        );
    };

    const searchCollapsed = <div className="search-collapsed"
            onClick={() => setShowSearch(true)}
        >
            <span className="icon-search"/>
    </div>;

    const searchShown = <div
            className="search-history-back"
        >
            <div className="search-history">
                <input
                    className="form-control form-control-sm"
                    placeholder="检索历史记录"
                    value={condition}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            searchHistory();
                        }
                    }}
                    onChange={(e) => {
                        setCondition(e.target.value);
                    }}
                />
                <span
                    className='icon-search'
                    onClick={searchHistory}
                />
            </div>
            <div className="clear-history">
                <div className="text-secondary fw-light me-1">清屏</div>
                <span
                    className='icon-do_not_disturb_alt'
                    onClick={() => setHistoryList([])}
                />
            </div>
            <div className="close-search"
                onClick={() => setShowSearch(false)}
            >
                <span className='icon-clear' />
            </div>
        </div>;

    const searchElem = showSearch ? searchShown : searchCollapsed;

    const showError = (newHistory, error) => {
        const lci = newHistory.length - 1;
        const prev = newHistory[lci]?.content || '';
        newHistory[lci] = {
            role: 'error',
            content: prev,
            selected: newHistory[lci].selected,
            html: {
                speed: SPEED,
                isLoading: false,
                isError: true,
                prevText: prev,
                completion: JSON.stringify(error),
            }
        };
        renderHistoryItem(newHistory[lci]);
    };

    function drawQuoteButton(qc, h, i)
    {
        if (!h.selected || !h.content) {
            return;
        }
        qc.push(
            <div
                key={i}
                className="quotes"
            >
                <div>
                    {h.content}
                </div>
                <span
                    className="icon-remove_circle_outline quoted-chats-deselect"
                    onClick={h.toggleSelection}
                />
            </div>
        );
    }

    return (<>
        {searchElem}
        <HistoryListComponent
            historyList={historyList}
            setShowSearch={setShowSearch}
        />
        <PromptInputComponent
            hint="输入问题，"
            quotedChats={quotedChats}
            stopFetching={stopFetching}
            submitPrompt={submitPrompt}
            visionImages={visionImages}
            setVisionImages={setVisionImages}
            isLoading={isLoading}
        />
    </>);
}

function HistoryListComponent({historyList, setShowSearch})
{
    /**
     * Must use a ref, event.target won't work.
     */
    const historyRef = useRef();
    const userScrolled = useRef(false);

    const renderedHistory = historyList ? historyList.map((item, index) => {
        return <CompletionComponent
            {...item.html}
            key={index}
        />;
    }) : <Spinner size="3rem" color="#b0f" />;

    const userScrolling = () => {
        userScrolled.current = isScrolled();
    };

    const isScrolled = () => {
        const he = historyRef.current;
        if (he) {
            const {scrollHeight, scrollTop, clientHeight} = he;
            const {height} = he.getBoundingClientRect();
            /**
             * scrollTop is a non-rounded number, while scrollHeight and
             * clientHeight are rounded — so the only way to determine if the
             * scroll area is scrolled to the bottom is by seeing if the scroll
             * amount is close enough to some threshold.
             */
            //console.log(scrollHeight - scrollTop - clientHeight);
            if (scrollTop < 40) {
                setShowSearch(true);
            } else {
                setShowSearch(false);
            }
            return Math.abs(scrollHeight - scrollTop - clientHeight) > 0;
        }
        return false;
    };

    useEffect(() => {
        checkScroll();
    });

    useEffect(() => {
        console.log('history list changes');
        userScrolled.current = false;
        checkScroll();
    }, [historyList.length]);

    const scrollToBottom = () => {
        const hr = historyRef.current;
        if (hr) {
            hr.scrollTop = hr.scrollHeight;
        }
    };

    const checkScroll = (lastScrollHeight) => {
        if (userScrolled.current) {
            return;
        }
        scrollToBottom();
        setTimeout(() => {
            const scrollableDiv = historyRef.current;
            if (scrollableDiv) {
                const {scrollHeight} = scrollableDiv;
                if (scrollHeight !== lastScrollHeight) {
                    checkScroll(scrollHeight);
                }
            }
        }, 50);
    };

    return <div
        className="history"
        onWheel={userScrolling}
        onTouchMove={userScrolling}
        ref={historyRef}
    >
        {renderedHistory}
    </div>;
}

export default ChatGPT;

