import React, { useState, useEffect, useRef } from 'react';
import {Utils, WebSocket, accessCode} from './bpdbcrud.js';
import Modal from './Modal.js';
import Snapshot, { TakePicture} from './Snapshot.js';
import ImageCropper from './ImageCropper.js';
import Image from './Image';
import './PromptInput.css';

const Recorder = window.Recorder;
let voiceRecorder;

const MAX_SILENCE = 10;
const MAX_RECORDING_TIME = 120;
function PromptInputComponent({
    quotedChats, stopFetching, submitPrompt, visionImages,
    setVisionImages, isLoading, hint = ''
})
{
    const [userInput, setUserInput] = useState('');
    const [whisperEnabled, setWhisperEnabled] = useState(true);
    const [showTimer, setShowTimer] = useState(false);
    const [showPictureSelection, setShowPictureSelection] = useState(false);
    const [timerBlink, setTimerBlink] = useState(false);
    const [speechTimer, setSpeechTimer] = useState({});
    const [isListening, setIsListening] = useState('stopped');
    const [currentTranscript, setCurrentTranscript] = useState('');
    const [typedCharacter, setTypedCharacter] = useState({key: ''});
    const [silence, setSilence] = useState({start: -1});
    const [quotedChatsCollapsed, setQuotedChatsCollapsed] = useState(false);
    const [image4cropper, setImage4Cropper] = useState();
    const [showSnapshot, setShowSnapshot] = useState(false);
    const [snapshot, setSnapshot] = useState();
    const [showMask, setShowMask] = useState(false);

    const snapshotRef = useRef();

    const shot4vision = async () => {
        if (snapshot) {
            /**
             * Set a 2-element array to image4cropper, where the 2nd element
             * of the array indicates if this image should be appended to the
             * vision array when it's 0, or a replacement should be taken
             * place if it's greater than 0 whose value is the index of the
             * image in the vision array that needs to be replaced.
             */
            setImage4Cropper([await snapshot.shot(), 0]);
            //await snapshot.stop();
            //setSnapshot(null);
            //setShowSnapshot(false);
        }
    };

    useEffect(() => {
        if (snapshot) {
            (async () => {
                await snapshot.start(snapshotRef.current);
            })();
        }
    }, [snapshot]);

    useEffect(() => {
        if (showSnapshot) {
            setSnapshot(new Snapshot());
        }
    }, [showSnapshot]);

    const onProcess = (buffers, powerLevel, bufferDuration,
           bufferSampleRate, newRec) => {
        try {
            if (silence.start < 0) {
                silence.start = Date.now();
            }
            if (powerLevel > 1) {
                silence.hasVoice = true;
                silence.start = Date.now();
                clearTimeout(silence.to);
                const t = timerRef.current;
                if (t) {
                    t.style.backgroundColor = 'orange';
                    silence.to = setTimeout(() => {
                        t.style.backgroundColor = '';
                    }, 1000);
                }
            } else if (silence.start > 0
                       && Date.now() - silence.start > MAX_SILENCE * 1000) {
                console.log('long time silence');
                voiceRecorder.rec = newRec;
                stopRecording();
            }
            /*
            if (newRecorder.wave) {
                newRecorder.wave.input(
                    buffers[buffers.length-1],
                    powerLevel,
                    bufferSampleRate
                );
            }
            */
        } catch (error) {
            console.log(error);
        }
    };

    const timerRef = useRef();
    const enableSpeech = () => {
        if (Recorder.IsOpen()) {
            Recorder.Destroy();
        }
        return new Promise((resolve, reject) => {
            const newRecorder = {
                rec: null,
                wave: null,
            };
            const newRec = Recorder({
                type: 'mp3',
                sampleRate: 44100,
                bitRate: 16,
                onProcess: (
                        buffers, powerLevel,
                        bufferDuration, bufferSampleRate) => {
                    onProcess(buffers, powerLevel, bufferDuration,
                      bufferSampleRate, newRec);
                }
            });
            newRec.open(() => {
                newRecorder.rec = newRec;
                voiceRecorder = newRecorder;
                setWhisperEnabled(true);
                resolve(false);
            }, (error, userNotAllowed) => {
                if (userNotAllowed) {
                    Utils.message('未获授权', '请确认录音权限');
                } else {
                    Utils.message('错误', error);
                }
                setWhisperEnabled(false);
                resolve(true);
            });
        });
    };

    const startRecording = async () => {
        if (isListening !== 'stopped') {
            return;
        }

        if (await enableSpeech()) {
            return;
        }

        if (!(voiceRecorder?.rec && Recorder.IsOpen())) {
            Utils.message('录音失败', '请确认录音权限');
            setWhisperEnabled(false);
            return;
        }

        //voiceRecorder.wave = Recorder.WaveSurferView({elem: '.wave-view'});

        voiceRecorder.rec.start();

        setIsListening('recording');

        setCurrentTranscript('');
        silence.hasVoice = false;
        setSilence(silence);

        setSpeechTimer((prevSpeechTimer) => {
            setShowTimer(true);
            clearInterval(prevSpeechTimer.p);
            prevSpeechTimer.r = MAX_RECORDING_TIME;
            prevSpeechTimer.p = setInterval(() => {
                if (prevSpeechTimer.r === 11) {
                    prevSpeechTimer.r = 10;
                    setTimerBlink(true);
                } else if (--prevSpeechTimer.r <= 0) {
                    stopRecording();
                }
                setSpeechTimer({ ...prevSpeechTimer });
            }, 1000);
            return prevSpeechTimer;
        });
    };

    const stopRecording = () => {
        clearSpeechTimer();
        setIsListening((prevIsListening) => {
            if (prevIsListening === 'recording') {
                silence.start = -1;
                setSilence(silence);
                if (!(voiceRecorder.rec && Recorder.IsOpen())) {
                    setIsListening('stopped');
                    Utils.message('错误', '录音未打开');
                    return;
                }
                //console.log(rec.rec.envInLast - rec.rec.envInFirst);
                voiceRecorder.rec.stop((blob, duration) => {
                    if (silence.hasVoice) {
                        uploadRecording(blob);
                    } else {
                        setIsListening('stopped');
                    }
                    voiceRecorder.rec.close();
                }, (msg) => {
                    console.log('Stop recording failed', msg);
                    Utils.message('错误', msg);
                });
                setCurrentTranscript('');
                return 'waiting';
            }
            return prevIsListening;
        });
    };

    const cancelListening = () => {
        clearSpeechTimer();
        setIsListening((prevIsListening) => {
            if (prevIsListening === 'recording') {
                silence.start = -1;
                setSilence(silence);
                setIsListening('stopped');
                voiceRecorder.rec.close();
                return 'waiting';
            }
            return prevIsListening;
        });
    };

    let rt = speechTimer.r || 0;
    const displayTimer = () => {
        const digits = [];
        while (rt > 0) {
            const r = rt % 10;
            digits.unshift(r);
            rt = (rt - r) / 10;
        }
        return digits.map((v, k) => {
            return <span key={k}>{v}</span>;
        });
    };

    const insertIntoTextarea = (text) => {
        const ta = inputRef.current;
        if (!ta) {
            return;
        }
        const start = ta.selectionStart;
        const end = ta.selectionEnd;
        const v = ta.value;
        if (start >= v.length) {
            setUserInput((prev) => prev + text);
        } else {
            setUserInput((prev) =>
                prev.substring(0, start) + text + prev.substring(end)
            );
        }
    };

    const uploadRecording = async (blob) => {
        const socket = WebSocket.create(
            () => {
                socket.on('transcript', (transcript) => {
                    if (transcript.error) {
                        Utils.message('语音转换出错',
                            JSON.stringify(transcript.error)
                        );
                    } else {
                        insertIntoTextarea(transcript.transcript);
                    }
                });
                socket.emit('speech', accessCode.readAccessCode(), blob);
            },
            () => {
                setIsListening('stopped');
            },
            (err) => {
                Utils.message('语音转换错误', JSON.stringify(err));
                setIsListening('stopped');
            },
            (err) => {
                Utils.message('语音转换错误', JSON.stringify(err));
                setIsListening('stopped');
            }
        );
    };

    function clearSpeechTimer()
    {
        setSpeechTimer((prevSpeechTimer) => {
            setTimerBlink(false);
            setShowTimer(false);
            clearInterval(prevSpeechTimer.p);
            return {r: MAX_RECORDING_TIME, p: 0};
        });
    }

    useEffect(() => {
        window.addEventListener('beforeunload', stopRecording);
        return () => {
            stopRecording();
            window.removeEventListener('beforeunload', stopRecording);
        };
    }, []);

    useEffect(() => {
        autoGrowInput();
    }, [currentTranscript, userInput]);

    const inputRef = useRef(); // Add a reference for the input field
    function clearInput()
    {
        setUserInput('');
        setShowMask(false);
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';
        }
    }

    const autoGrowInput = () => {
        if (inputRef.current) {
            const textarea = inputRef.current;
            textarea.style.height = 'auto';
            /**
             * When the first time the page is loaded, Painter component is
             * hidden.
             */
            if (textarea.scrollHeight > 30) {
                textarea.style.height = `${textarea.scrollHeight + 2}px`;
            }
        }
    };

    const condenseTextarea = (e) => {
        setShowMask(false);
        if (inputRef.current) {
            inputRef.current.style.cssText = 'max-height: initial;';
        }
    };

    function submitText(userInput)
    {
        if (isLoading || userInput.trim() === '') {
            return;
        }

        setTypedCharacter({key: ''});

        if (userInput) {
            stopRecording();
        }
        if (submitPrompt(userInput)) {
            clearInput();
        }
    }

    const expandTextarea = (e) => {
        setShowMask(true);
        if (inputRef.current) {
            inputRef.current.style.cssText = '';
            autoGrowInput();
        }
    };

    const visionArea = () => {
        if (!visionImages?.length) {
            return null;
        }
        const list = [];
        visionImages.forEach((image, i) => {
            const iu = URL.createObjectURL(image);
            list.push(
                <div key={i}>
                    <div className="edit-remove">
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                                setImage4Cropper([image, i + 1]);
                            }}
                        >
                            <span className="icon-create"/>
                        </button>
                        <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {
                                visionImages.splice(i, 1);
                                setVisionImages([...visionImages]);
                            }}
                        >
                            <span className="icon-clear"/>
                        </button>
                    </div>
                    <img src={iu} />
                </div>
            );
        });
        return <div className="vision-area">
            {list}
        </div>
    };

    const quotedChatsArea = () => {
        const updown = quotedChatsCollapsed ?
            {ud: 'up', dp: 'hide-chats'} : {ud: 'down', dp: ''};
        return <div
            className={quotedChats?.length ? 'show-chats' : 'hide-chats'}
        >
            <div
                className="quoted-chats"
            >
                <div
                    className="quoted-chats-title"
                    onClick={(e) => {
                        /**
                         * Prevent the event from bubbling up to the input
                         * container which will expand the textarea.
                         */
                        e.stopPropagation();
                        setQuotedChatsCollapsed(p => !p);
                    }}
                >
                    <div>引用的内容</div>
                    <span
                        className={`icon-keyboard_arrow_${updown.ud}`}
                    />
                </div>
                <div className={`quoted-chats-list ${updown.dp}`}>
                    {quotedChats}
                </div>
            </div>
            <div className="quoted-chats-mask"/>
        </div>;
    };

    const inputChanged = (e) => {
        //const position = e.target.selectionStart;
        const v = e.target.value;
        //if (position >= v.length || !/[\r\n]/.test(userInput)) {
            if (typedCharacter.key === 'Enter' && typedCharacter.shiftKey) {
                e.preventDefault();
                setTypedCharacter({key: ''});
                submitText(userInput);
                return;
            }
        //}
        setUserInput(v);
    };

    const handleTypedCharacter = (e) => {
        setTypedCharacter(e);
    };

    function toggleListening()
    {
        isListening === 'recording' ? stopRecording() : startRecording();
    }

    const poppedTimer = showTimer ? <div className="top-panel">
        <div
            className={
                `recorder-back ${timerBlink ? 'blink-border' : ''}`
            }
        >
            <div className="remain">
                剩余（静音{MAX_SILENCE}秒自动停止）
            </div>
            <div ref={timerRef} className="my-1">
                <div className="timer">
                    {displayTimer()}
                </div>
            </div>
            <div className="d-flex justify-content-around w-100">
                <button
                    className="record-button recording"
                    onClick={toggleListening}
                >
                    <span className="icon-mic_off" />
                </button>
                <button
                    className="record-button bg-secondary"
                    onClick={cancelListening}
                >
                    <span className="icon-clear" />
                </button>
            </div>
        </div>
    </div> : null;

    const mask = showMask ? <div
        className="prompt-input-mask"
        onClick={condenseTextarea}
        onWheel={condenseTextarea}
        onTouchMove={condenseTextarea}
    ></div> : null;

    const operations = [{
        click: () => {
            TakePicture.createFileSelector((imageFile) => {
                setImage4Cropper([imageFile, 0]);
            });
        },
        element: <span className="icon-sd_storage"/>
    }, {
        click: () => {
            setShowSnapshot(true);
        },
        element: <span className="icon-camera_alt"/>
    }];

    const pictureSelection = showPictureSelection ? <Modal
        event={showPictureSelection}
        operations={operations}
        onClose={() => setShowPictureSelection(false)}
    /> : null;

    const recorderButton = <button
        disabled={
            isListening === 'waiting' || !whisperEnabled
                || isLoading
        }
        className={
            `record-button
                ${whisperEnabled ? isListening : ''}`
        }
        onClick={toggleListening}
    >
        <span
            className={isListening === 'recording' ?
                "icon-mic_off" : "icon-mic1"}
        />
    </button>;
    const pictureButton = typeof setVisionImages === 'function' ?
        <button
            className="ocr-button"
            onClick={(e) => {
                // Don't call e.stopPropagation() here, we need to
                // expand the textarea if this button is clicked.
                setShowPictureSelection(prev => prev ? false : e);
            }}
            disabled={visionImages.length >= 3 || isLoading}
        >
            <span
                className="icon-picture"
                style={{pointerEvents: 'none', fontSize: '1rem'}}
            />
        </button> : null;

    const textarea = <textarea
        placeholder={`${hint} Shift + Enter提交`}
        rows="1"
        className="form-control"
        type="text"
        value={userInput +
            (currentTranscript ? currentTranscript : '')}
        onChange={inputChanged}
        onKeyPress={handleTypedCharacter}
        ref={inputRef}
        /**
         * When tabbed into this textarea, onClick will not be
         * triggered.
         */
        onFocus={expandTextarea}
        onWheel={expandTextarea}
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            expandTextarea(e);
        }}
        disabled={isListening !== 'stopped' || isLoading}
    />;

    const inputContainer = <div
        className="input-container"
        style={{
            "--textarea-padding-left": pictureButton ? "80px" : "46px"
        }}
    >
        {mask}
        {poppedTimer}
        <div className="chats-input">
            <div className="vision-container">
                {visionArea()}
            </div>
            {quotedChatsArea()}
            {textarea}
        </div>
        <div className="record-send-container">
            <div className="record-ocr">
                {recorderButton}
                {pictureButton}
            </div>
            <button
                className={`send-button ${userInput ? 'ready' : ''}`}
                onClick={() => {
                    if (isLoading) {
                        stopFetching();
                    } else {
                        submitText(userInput)}
                    }
                }
                disabled={isListening !== 'stopped'}>
                <span className={
                    `icon-${isLoading ? 'stop2 sending' : 'send'}`
                }/>
            </button>
        </div>
    </div>;

    return <>
        { showSnapshot ?
            <div ref={snapshotRef} className="snapshot-area">
                <video playsInline={true} autoPlay={true} />
                <div className="d-flex w-50 mt-1 justify-content-between">
                    <button
                        className="btn btn-primary"
                        onClick={shot4vision}
                    >
                        <span className="icon-camera"/>
                    </button>
                    <button
                        className="btn btn-warning"
                        onClick={async () => {
                            await snapshot.stop();
                            setSnapshot(null);
                            setShowSnapshot(false);
                        }}
                    >
                        <span className="icon-clear"/>
                    </button>
                </div>
            </div> : null
        }
        { image4cropper ?
            <div className="ocr-area">
                <ImageCropper
                    imageFile={image4cropper[0]}
                    setResult={async (croppedImage) => {
                        if (image4cropper[1]) {
                            visionImages[image4cropper[1] - 1] = croppedImage;
                        } else {
                            if (visionImages.length >= 3) {
                                Utils.tipOnTop('最多三张图片');
                                return;
                            }
                            visionImages.push(croppedImage);
                        }
                        setVisionImages([...visionImages]);
                        setImage4Cropper(null);

                        if (snapshot) {
                            await snapshot.stop();
                            setSnapshot(null);
                            setShowSnapshot(false);
                        }
                    }}
                />
                <button
                    className="ocr-area-close icon-clear"
                    onClick={() => {
                        setImage4Cropper(null);
                    }}
                />
            </div> : null
        }
        {pictureSelection}
        {inputContainer}
    </>;
}

export default PromptInputComponent;
