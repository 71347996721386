import React, { useEffect, useState, useRef } from 'react';
import logo from './icon.png';
import UserList, {UserView} from './UserList.js';
import ModelList from './ModelList.js';
import InstructionList from './InstructionList.js';
import { fetch, whoAmI, login, CRUD, Utils } from './bpdbcrud.js';

Utils.socketServerPort = 3200;
function Admin()
{
    window.document.title = 'Admin Panel for AI';
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    async function checkLoginStatus()
    {
        const me = await whoAmI();
        if (me && me[0]?.loginName) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }

    useEffect(() => {
        checkLoginStatus();
    }, []);

    const doLogin = async () => {
        if (Utils.isEmpty(username) || Utils.isEmpty(password)) {
            Utils.tipOnTop('用户名和密码不得为空');
            return;
        }

        setLoggedIn(await login(username, password));
    };

    const logout = async () => {
        await fetch('r/logout', {
            method: 'GET'
        });
        setLoggedIn(false);
    };

    function show()
    {
        if (loggedIn) {
            return <AdminPanel />
        } else {
            return <div className="">
                <input
                    className="form-control my-1"
                    type="text"
                    placeholder="用户名"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    className="form-control"
                    type="password"
                    placeholder="密码"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button
                    className="btn btn-success w-100 my-3"
                    onClick={doLogin}
                >
                    登录
                </button>
            </div>;
        }
    }

    return (<>
        <div className="App-header">
            <div className="nav-header">
                <div className="brand">
                    <img className="nav-logo" src={logo} alt="" />
                    控制台
                </div>
                <div
                    className={`${loggedIn ? 'd-flex' : 'd-none'}`}
                    onClick={logout}
                >
                    <span className="icon-sign-out"/>
                </div>
            </div>
        </div>
        {show()}
    </>);
}

function Navbar({active, setActive, extraButtons})
{
    const buttons = [{
        display: '用户'
    }, {
        display: '指令'
    }, {
        display: '模型'
    }, {
        display: '数据'
    }];

    const activeRef = useRef();
    useEffect(() => {
        if (activeRef.current) {
            activeRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
        }
    });
    function buttonsDiv()
    {
        return buttons.map((button, index) => {
            return <div
                key={index}
                ref={active === index ? activeRef : null}
            >
                <button
                    className={`nav-link${active === index ? ' active' : ''}`}
                    onClick={() => {
                        setActive(index);
                    }}
                >
                    {button.display}
                </button>
            </div>;
        });
    }

    function extraButtonsDiv()
    {
        return extraButtons?.map((button, i) => {
            const index = buttons.length + i;
            return <div
                ref={active === index ? activeRef : null}
                key={index}
                className={`d-flex position-relative`}
            >
                <button
                    className={`extra-button nav-link
                        ${active === index ? ' active' : ''}`}
                    onClick={() => {
                        setActive(index);
                    }}
                >
                    {button.display}
                    {typeof button.close === 'function' && active === index ?
                        <span
                            className="icon-clear"
                            onClick={(e) => {
                                e.stopPropagation();
                                button.close()
                            }}
                        /> : null
                    }
                </button>
            </div>;
        });
    }

    return <div className="nav nav-tabs">
        <div className="buttons">
            {buttonsDiv()}
            {extraButtonsDiv()}
        </div>
    </div>;
}

function AdminPanel()
{
    const [active, setActive] = useState(0);
    const [extraButtons, setExtraButtons] = useState([]);
    const [extraComponents, setExtraComponents] = useState([]);

    const userMap = useRef([]);
    function generateExtra()
    {
        const updateUser = (user) => {
            for (let i = 0; i < userMap.current.length; i++) {
                if (userMap.current[i].tempId === user.tempId) {
                    user.tagId = user.id;
                    userMap.current[i] = user;
                    generateExtra();
                    break;
                }
            }
        };
        const extraButtons = [];
        const extraComponents = [];
        const close = (userView) => {
            const index = userMap.current.indexOf(userView);
            userMap.current.splice(index, 1);
            extraButtons.splice(index, 1);
            extraComponents.splice(index, 1);
            setExtraButtons([...extraButtons]);
            setExtraComponents([...extraComponents]);
            setActive(0);
        };
        userMap.current.forEach((userView) => {
            extraButtons.push({
                display: userView.field?.phone || '新建',
                close: () => close(userView)
            });
            extraComponents.push(<UserView
                {...userView}
                updateUser={updateUser}
                close={() => close(userView)}
            />);
        });
        setExtraButtons([...extraButtons]);
        setExtraComponents([...extraComponents]);
    }
    const setUserView = (userView) => {
        if (userView.id) {
            for (let i = 0; i < userMap.current.length; i++) {
                const st = userMap.current[i];
                if (st.tagId === userView.id) {
                    setActive(i + components.length);
                    return;
                }
            }
            userView.tagId = userView.id;
        }
        userMap.current.push(userView);
        generateExtra();
        setActive(components.length + userMap.current.length - 1);
    };

    const [components] = useState([
        <UserList setUserView={setUserView} />,
        <InstructionList />,
        <ModelList />,
        <CRUD />
    ]);

    const navbar = <Navbar {...{
        active,
        setActive,
        extraButtons
    }} />;
    const workspaceRef = useRef();
    useEffect(() => {
        const setHeight = () => {
            const elem = workspaceRef.current;
            if (elem) {
                console.log('workspace resized');
                const h = window.innerHeight -
                    elem.getBoundingClientRect().top;
                elem.style.height = `${Math.floor(h)}px`;
            }
        };

        setHeight();
        window.addEventListener('resize', setHeight);
        return () => {
            window.removeEventListener('resize', setHeight);
        };
    });
    return <div className="admin-panel">
        {navbar}
        <div className="workspace" ref={workspaceRef}>
            {[...components, ...extraComponents].map((com, index) => {
                return <div
                    className={`h-100
                        ${active === index ? '' : 'd-none'}`}
                    key={`${com?.props?.tagId || index}`}
                >
                    {com}
                </div>;
            })}
        </div>
    </div>
}

export default Admin;

