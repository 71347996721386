import './Main.css';
import './Painter.css';
import React, { useState, useEffect, useRef } from 'react';
import {Utils, WebSocket, DropdownMenu, accessCode} from './bpdbcrud.js';
import PromptInputComponent from './PromptInput.js';
import Image from './Image';

const Painter = React.forwardRef((props, ref) => {
    const {onInvalidSession} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [fetchSocket, setFetchSocket] = useState(false);
    const [paintingList, setPaintingList] = useState([]);
    const [condition, setCondition] = useState('');

    const modelList = useRef([]);
    const model = useRef();

    function stopFetching()
    {
        if (fetchSocket) {
            fetchSocket.disconnect();
        }
        setIsLoading(false);
    }

    function searchPaints(condition)
    {
        const socket = WebSocket.create(
            () => {
                Utils.showLoading();
                socket.on('paintinglist', (paints) => {
                    console.log(paints);
                    Utils.hideLoading();
                    socket.disconnect();
                    if (paints.error) {
                        Utils.tipOnTop(JSON.stringify(paints.error));
                        return;
                    }
                    modelList.current = [];
                    const models = paints.instruction?.models;
                    for (const m in models) {
                        modelList.current.push({
                            name: m,
                            ...models[m]
                        });
                    }
                    const pl = [];
                    for (const p of paints.paintingList.reverse()) {
                        pl.push({
                            hid: p.id,
                            images: parseInt(p.field.image[0]),
                            prompt: p.field.prompt[0],
                            revised_prompt: p.field.revised_prompt[0],
                            time: p.field.time[0]
                        });
                    }
                    setPaintingList(pl);
                });
                socket.emit(
                    'paintinglist',
                    accessCode.readAccessCode(),
                    condition
               );
            },
            () => {
            },
            /**
             * On connect error.
             */
            (err) => {
                console.log(err);
                Utils.tipOnTop(JSON.stringify(err));
            },
            /**
             * On error.
             */
            (err) => {
                if (err.error === 'invalidsession') {
                    onInvalidSession();
                } else if (err.error === 'insufficient') {
                    Utils.tipOnTop(JSON.stringify(err));
                }
                Utils.hideLoading();
            }
        );
    }

    useEffect(() => {
        searchPaints();
    }, []);

    const submitPrompt = (submission) => {
        if (submission) {
            if (!model.current) {
                Utils.message('请选择一个模型');
                return false;
            }
            fetchImage(submission);
        }
        return true;
    };

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }, [paintingList]);

    const fetchImage = async (prompt) => {
        setIsLoading(true);
        setPaintingList(prev => {
            prev.push({
                prompt: prompt
            });
            return [...prev];
        });
        /*
        setTimeout(() => {
            setIsLoading(false);
            submitPrompt(false);
            setPaintingList(prev => {
                prev.pop();
                prev.push(prev[0]);
                return [...prev];
            });
        }, 3000);
        return;
        */

        const socket = WebSocket.create(
            /**
             * On connect.
             */
            () => {
                setFetchSocket(socket);
                socket.on('paint', (paint) => {
                    console.log(paint);
                    if (paint.error) {
                        Utils.tipOnTop(JSON.stringify(paint.error));
                    } else {
                        setPaintingList(prev => {
                            prev[prev.length - 1] = {
                                ...paint,
                                prompt: prompt
                            };
                            return [...prev];
                        });
                    }
                });
                socket.emit(
                    'paint',
                    accessCode.readAccessCode(),
                    prompt,
                    model.current
                );
            },
            /**
             * On disconnect.
             */
            () => {
                setIsLoading(false);
                submitPrompt(false);
                setFetchSocket(false);
            },
            /**
             * On connect error.
             */
            (err) => {
                setIsLoading(false);
                submitPrompt(false);
                paintingList.pop();
            },
            /**
             * On error.
             */
            (err) => {
                paintingList.pop();
                setPaintingList([...paintingList]);
                if (err.error === 'invalidsession') {
                    onInvalidSession();
                } else if (err.error === 'insufficient') {
                    Utils.message('余额不足', '请充值');
                } else {
                    Utils.tipOnTop(err.error);
                }
            }
        );

    };

    return (<>
        <div className="paint-area">
            <PaintingList
                ref={ref}
                paintingList={paintingList}
            />
            <div className="model-search">
                <ModelSelector
                    updateModel={m => model.current = m.name}
                    modelList={modelList.current}
                />
                <div className="search">
                    <input
                        value={condition}
                        onChange={(e) => setCondition(e.target.value)}
                    />
                    <button onClick={() => searchPaints(condition)}>
                        <span className="icon-search"/>
                    </button>
                </div>
            </div>
        </div>
        <PromptInputComponent
            hint="描述要画的内容，"
            stopFetching={stopFetching}
            submitPrompt={submitPrompt}
            isLoading={isLoading}
        />
    </>);
});

function ModelSelector({updateModel, modelList})
{
    const [model, setModel] = useState(false);
    /*
    useEffect(() => {
        if (!model && modelList?.[0]) {
            setModel(modelList[0]);
            updateModel(modelList[0]);
        }
    }, [modelList]);
    */

    return <div className="model-selector">
        <DropdownMenu
            title={model ? `${model.size} ${model.quality}` : '选择模型'}
            list={modelList}
            extraClass="color-success"
            onMenuClick={(item) => {
                setModel(item);
                updateModel(item);
            }}
            popUp={true}
            display={(model) => `${model.size} ${model.quality}`}
        />
    </div>;
}

const PaintingList = React.forwardRef(({paintingList}, ref) => {
    return <div
        className="painting-list"
        ref={ref}
    >
        {paintingList.map((paint, index) => {
            const h = Utils.getHost();
            const imgList = [];
            for (let i = 0; i < paint.images; i++) {
                const src = h ? `http://${h}/gp/${paint.hid}/${i}` :
                    `gp/${paint.hid}/${i}`;
                imgList.push(<Image src={src} key={index} />);
            }

            return <div
                className={`one-paint${paint.images ? '' : ' blinking'}`}
                key={index}
            >
                <PromptArea paint={paint} />
                <div className="image-list">
                    {imgList}
                </div>
            </div>;
        })}
    </div>;
});

function PromptArea({paint})
{
    const [show, setShow] = useState(false);
    return <>
        <button
            className="show-prompts-button"
            onClick={() => setShow(!show)}
        >
            <span className="icon-ellipsis-h"/>
        </button>
        {show ? <div className="prompts">
            <div className="prompt">
                {paint.prompt}
            </div>
            {paint.revised_prompt ? <div className="revised_prompt">
                {paint.revised_prompt}
            </div> : null}
        </div> : null}
    </>;
}

export default Painter;

