import React, { useRef, useEffect, useState, useImperativeHandle, forwardRef }
    from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {Utils} from './bpdbcrud.js';

const ImageCropperRef = forwardRef((props, ref) => {
    Utils.showLoading();

    /*
    useImperativeHandle(ref, () => ({
        rotate: (degree) => {
            const cropper = ref.current?.cropper;
            if (cropper) {
                cropper.clear();
                cropper.rotate(degree);
            }
        }
    }));
    */

    const onImageLoaded = () => {
        Utils.hideLoading();
        const cropper = ref.current.cropper;
        const containerData = cropper.getContainerData();
        cropper.setCropBoxData({
            left: 0,
            top: 0,
            width: containerData.width,
            height: containerData.height,
        });
    };

    const cw = window.innerWidth, ch = window.innerHeight - 100;
    const [w, h] = cw > ch ? [ch, ch] : [cw, cw];
    return (
        <Cropper
            src={props.src}
            style={{ height: h, width: w }}
            initialAspectRatio={1}
            guides={false}
            ref={ref}
            viewMode={2}
            autoCropArea={1}
            autoCrop={true}
            ready={onImageLoaded}
        />
    );
});

function ImageCropper({imageFile, setResult})
{
    const [selectedImage, setSelectedImage] = useState(null);
    const cropperRef = useRef(null);

    useEffect(() => {
        setSelectedImage(URL.createObjectURL(imageFile));
    }, [imageFile]);

    const onCrop = () => {
        Utils.showLoading();
        setTimeout(() => {
            const cropper = cropperRef.current?.cropper;
            if (cropper) {
                const croppedCanvas = cropper.getCroppedCanvas({
                    maxWidth: 2048,
                    maxHeight: 2048
                });
                //const croppedImage = croppedCanvas.toDataURL('image/png');
                //setResult(croppedImage);
                croppedCanvas.toBlob(
                    (blob) => {
                        setResult(blob);
                    },
                    'image/jpeg'
                );
            }
            Utils.hideLoading();
        }, 10);
    };

    const rotateImage = (degree) => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.clear();
            cropper.rotate(degree);
        }
    };

    return (<>
        { selectedImage && (<>
            <ImageCropperRef ref={cropperRef} src={selectedImage} />
            <div>
                <button
                    className="btn btn-sm btn-dark m-1"
                    onClick={() => rotateImage(90)}
                >
                    <span className="icon-rotate-cw"/>
                </button>
                <button
                    className="btn btn-sm btn-dark m-1"
                    onClick={() => rotateImage(-90)}
                >
                    <span className="icon-rotate-ccw"/>
                </button>
                <button
                    className="btn btn-sm btn-primary m-1"
                    onClick={onCrop}
                >
                    <span className="icon-scissors"/>
                </button>
            </div>
        </>)}
    </>);
}

export default ImageCropper;

