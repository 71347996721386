import React, { useEffect, useState, useRef } from 'react';
import {
    Utils, WebSocket, DataEditor, Overlay, Table, getDataType, query, fetch
} from './bpdbcrud.js';

function ModelList()
{
    const [modelList, setModelList] = useState(false);
    const [modelView, setModelView] = useState(false);
    const easy = useRef({});

    const refresh = async () => {
        const list = await getModelList({
            page: 1, pageSize: 0
        });
        if (list.error) {
            return;
        }
        setModelList(list.extra);
        easy.current = list.extra.easy;
    };

    useEffect(() => {
        refresh();
    }, []);

    const resultTable = <Table
        resultList={modelList}
        pageSize={0}
        attributes={easy.current.attributes}
        rowClicked={(model) => setModelView({
            model,
            ...easy.current
        })}
    />

    const createModel = async () => {
        const dataType = await getDataType('model');
        if (dataType.error) {
            Utils.tipOnTop(dataType.code);
            return;
        }

        setModelView({
            model: {field: {}},
            ...dataType.easy
        });
    };

    return <div className="modellist">
        <div className="new-refresh">
            <button
                className="btn btn-primary"
                onClick={refresh}
            >
                刷新
            </button>
            <button
                className="btn btn-warning"
                onClick={createModel}
            >
                新建
            </button>
        </div>
        {modelList.total ? resultTable : null}
        {modelView ? <ModelView
            {...modelView}
            close={() => setModelView(false)}
        /> : null}
    </div>;
}

function ModelView(props)
{
    const { model, close } = props;

    const updateData = async (ret) => {
        if (ret.error) {
            return ret;
        }
        const one = JSON.parse(ret.extra);
        for (const m in one) {
            model[m] = one[m];
        }
        delete model.properties;
        for (const m in model.field) {
            delete model.field[m];
        }
        for (const m in one.properties) {
            model.field[m] = one.properties[m];
        }
        return ret;
    };

    const save = async ({
        dtName, id, values, force, version, security_level
    }) => {
        const ppp = {
            catalog: '/data',
            force: !!force,
            dt_name: dtName,
            form_data: JSON.stringify(values),
            id: id || '',
            security_level: security_level || 0
        }
        if (version) {
            ppp.version = version;
        }
        const params = new URLSearchParams(ppp);
        const h = Utils.getHost();
        const url = h ? `http://${h}/savemodel` : `/savemodel`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: params
        });
        if (response.ok) {
            return await response.json();
        } else {
            return {
                error: true,
                code: '保存模型失败'
            };
        }
    };

    const modelEditor = <DataEditor {...props}
        {...model}
        updateData={updateData}
        dataTableName="model"
        close={close}
        save={save}
    />;
    return <Overlay component={modelEditor} />;
}

async function getModelList({page = 1, pageSize, pattern, f2r}) {
    const criteria = [{
        n: 'created_time',
        s: 'int',
        f: 1,
        t: ''
    }];

    const list = await query(
        'model',
        criteria,
        '{n: "last_modified_time", s: "int", a: false}',
        page,
        pageSize || 0,
        f2r || ''
    );
    if (list.error) {
        Utils.tipOnTop('检索出错');
        return;
    }
    return list;
}

export default ModelList;
