import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Entry from './Entry.js';
import Admin from './Admin.js';
import { Utils } from './bpdbcrud.js';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Admin.css';
import './style.css';
//import './bpdbcrud.css';

Utils.socketServerPort = 3200;
function App()
{
    useEffect(() => {
        const setViewportHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setViewportHeight();
        window.addEventListener('resize', setViewportHeight);

        return () => {
            window.removeEventListener('resize', setViewportHeight);
        };
    });

    const path = Utils.getRequestPath();
    return (
        <Router>
            <Routes>
                <Route exact path={`${path}`} element={<Entry />} />
                <Route exact path={`${path}/admin`} element={<Admin />} />
            </Routes>
        </Router>
     );
}

export default App;

