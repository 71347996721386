import React, { useState, useEffect, useRef } from 'react';
import createTalk from './Talk.js';
import {Utils} from './bpdbcrud.js';
import { PlayBack } from './PlayBack.js';
import Snapshot from './Snapshot.js';

function TalkyPanel({historyTalkyMessages, saveToHistory, closeTalky})
{
    const [talking, setTalking] = useState(false);
    const [pauseTalking, setPauseTalking] = useState(false);
    const [talkyReady, setTalkyReady] = useState(false);
    const [talkSending, setTalkSending] = useState(false);
    const [talkyMessages, setTalkyMessages] = useState(historyTalkyMessages);
    const [talkingInProgress, setTalkingInProgress] = useState(false);
    const [gender, setGender] = useState(0);
    const [automatic, setAutomatic] = useState(false);
    const [text2Voice, setText2Voice] = useState(false);
    const [transcriptSwitch, setTranscriptSwitch] = useState(true);
    const [timeLeft, setTimeLeft] = useState(false);
    const [disableTalkyControl, setDisableTalkyControl] = useState(false);
    const [instructionOn, setInstructionOn] = useState(false);
    const [instruction, setInstruction] = useState('');
    const [speed, setSpeed] = useState('normal');
    const [showTips, setShowTips] = useState(true);

    const talkFactory = useRef();
    const talkCallbacks = useRef();
    const stillTalking = useRef();
    const poweredOn = useRef();
    const powerLevel = useRef();
    const timer = useRef();
    const audioRef = useRef();
    const audioMeRef = useRef();
    const messagesRef = useRef();

    const MAX_RECORDING_TIME = 120000;
    const SPEED = {
        'slow': '缓慢语速',
        'normal': '正常语速',
        'fast': '快速语速'
    };

    const scrollToBottom = () => {
        const scrollableDiv = messagesRef.current;
        if (scrollableDiv) {
            const {scrollHeight, clientHeight, scrollTop} = scrollableDiv;
            scrollableDiv.scrollTo(0, scrollHeight);
            setTimeout(() => {
                if (scrollHeight - clientHeight - scrollTop !== 0) {
                    scrollToBottom();
                }
            }, 100);
        }
    };

    function isPoweredOn()
    {
        return poweredOn.current;
    }

    function setPower(on)
    {
        poweredOn.current = on;
    }

    function isStillTalking()
    {
        return stillTalking.current;
    }

    function setStillTalking(still)
    {
        stillTalking.current = still;
    }

    useEffect(() => {
        talkCallbacks.current = false;
        setStillTalking(false);
        setPower(false);
        const audio = audioRef.current;
        talkFactory.current = createTalk({
            automatic: automatic,
            gender: gender,
            audio: audio,
            onReady: (ready) => {
                Utils.hideLoading();
                setTalkyReady(ready);
                if (!ready) {
                    setTalking(false);
                    setTalkingInProgress(false);
                }
            },
            onSending: (sending) => {
                setTalkSending(sending);
                if (sending) {
                    clearInterval(timer.current);
                    setTimeLeft(false);
                }
            },
            pauseTalking: (paused) => {
                setPauseTalking(paused);
            },
            onPowerLevel: (() => {
                let to;
                return (level) => {
                    clearTimeout(to);
                    const t = powerLevel.current;
                    t.style.backgroundColor = 'orange';
                    to = setTimeout(() => {
                        t.style.backgroundColor = '';
                    }, 1000);
                };
            })(),
            onMessage: (conversation) => {
                setTalkyMessages((prev) => {
                    const tl = prev.length - 1;
                    prev[tl] = conversation;
                    const n = [...prev];
                    saveToHistory(n);
                    return n;
                });
            }
        });
        return () => {
            window.Recorder.Destroy();
            setTalking(false);
            if (audio) {
                audio.pause();
                audio.terminated = true;
                //window.URL.revokeObjectURL(audio.src);
            }
            /**
             * If talking button is on, destroying the talking panel will throw
             * an exception on closing the recorder. Don't know why. Workaround
             * has been implemented in shutdownRecorder of Talk.
             */
            if (talkCallbacks.current?.stopTalking) {
                talkCallbacks.current?.stopTalking();
            }
        }
    }, []);

    useEffect(() => {
        talkFactory.current.setAutomatic(automatic);
        talkFactory.current.setGender(gender);
    }, [automatic, gender]);

    useEffect(() => {
        scrollToBottom();
    }, [talkyMessages]);

    const playAudioMe = (source) => {
        if (audioMeRef.current) {
            audioMeRef.current.pause();
            audioMeRef.current = false;
            setTalkingInProgress(false);
            setDisableTalkyControl(false);
            return;
        }

        if (talkingInProgress || !source) {
            return;
        }

        setTalkingInProgress(true);
        setDisableTalkyControl(true);
        /**
         * Use a standalone Audio object to play the voice of mine, leaving
         * audioRef for playing response voices only.
         */
        const audio = new Audio(); // works fine
        audioMeRef.current = audio;
        audio.volume = 1;
        try {
            audio.src = source;
        } catch (error) {
            console.log(error);
            return;
        }
        audio.oncanplaythrough = () => {
            audio.volume = 1;
            audio.play();
        };
        audio.onended = () => {
            setTalkingInProgress(false);
            setDisableTalkyControl(false);
            audioMeRef.current = false;
        };
        audio.load();
    };

    const playResponseClicked = (reply) => {
        if (talkingInProgress || !reply.playbackAudio || disableTalkyControl) {
            return;
        }
        setText2Voice({
            content: true,
            text: reply.response,
            voice: reply.playbackAudio
        });
    };

    function shutdownTalking()
    {
        setStillTalking(false);
        if (talkCallbacks.current?.stopTalking) {
            setTimeout(() => {
                talkCallbacks.current.stopTalking();
            }, 500);
        }
        setTalkyReady(false);
        setTalking(false);
        clearInterval(timer.current);
        setTimeLeft(false);
    }

    const talkyButtonPressed = async () => {
        const audio = audioRef.current;
        async function powerOn()
        {
            if (showTips) {
                setShowTips(false);
            }
            setStillTalking(false);
            setTalkingInProgress(true);
            const talk = talkFactory.current.talk;
            const ret = await talk({
                powerOff: async () => {
                    console.log('powered off');
                    setPauseTalking(false);
                    if (isStillTalking()) {
                        await powerOn();
                    } else {
                        setTalking(false);
                        setTalkyReady(false);
                        setPower(false);
                        setTalkingInProgress(false);
                    }
                },
                conversation: talkyMessages.at(-1),
                instruction: instruction,
                speed: speed
            });
            talkCallbacks.current = ret;

            clearInterval(timer.current);
            setTimeLeft(false);

            if (!automatic) {
                const startTime = Date.now();
                timer.current = setInterval(() => {
                    const left = startTime + MAX_RECORDING_TIME - Date.now();
                    if (left < 100) {
                        shutdownTalking();
                    } else if (left < 11000) {
                        setTimeLeft({ display: Math.floor(left / 1000) });
                    }
                }, 500);
            }
            return ret;
        }

        if (!talking) {
            setTalking(true);

            if (isPoweredOn()) {
                setStillTalking(true);
                return;
            }
            Utils.showLoading();
            if (await powerOn()) {
                setPower(true);
            }
        } else {
            shutdownTalking();
        }
    };

    const cancelListening = () => {
        if (talkCallbacks.current?.cancelTalking) {
            talkCallbacks.current?.cancelTalking();
        }
        clearInterval(timer.current);
        setTimeLeft(false);
    };

    const cancelButton = talking && !pauseTalking ? <div
        className="cancel-talking"
    >
        <div>
            <div className="nosleep">
                <video playsInline={true} autoPlay={true} loop={true}>
                    <source src="./nosleep.mp4" type="video/mp4"/>
                </video>
            </div>
            <button
                className="record-button"
                onClick={cancelListening}
            >
                <span className="icon-clear" />
            </button>
        </div>
    </div> : null;

    function loadingDots()
    {
        const span = <span style={{backgroundColor: 'white'}}/>;
        return <div className="loading-dots">
            {span}
            {span}
            {span}
        </div>;
    }

    function formatResponse(reply)
    {
        if (reply.response) {
            const bgc = transcriptSwitch ? '' : 'white';
            return reply.response.split('\n').map((item, index) => {
                return <span key={index} style={{backgroundColor: bgc}}>
                    {item}
                    {index !== reply.response.length - 1 && <br/>}
                </span>;
            });
        } else {
            return loadingDots();
        }
    }

    const displayTalkyMessages = () => {
        if (showTips && !talkyMessages?.[0].length) {
            return <div className="d-flex justify-content-center">
                <div className="control-tips">
                    点击处于弹起位置的灰色话筒
                    <div className="push-button">
                        <button className="pushable">
                            <span className="icon-mic1"/>
                        </button>
                    </div>
                    按钮开启录音。
                    <br/>
                    当出现闪烁的红色话筒
                    <div className="push-button">
                        <button className="pushable blinking-mic">
                            <span className="icon-mic1 blinking-mic"/>
                        </button>
                    </div>
                    时，可以讲话。
                    <br/>
                    点击
                    <button
                        className={`record-button`}
                    >
                        <span className="icon-clear" />
                    </button>
                    取消发送。
                    <br/>
                    在
                    <span className="bg-info text-white p-1 rounded">
                        手动模式
                    </span>
                    下，讲完之后再次点击该按钮，发送语音。
                    <br/>
                    在
                    <span className="bg-info text-white p-1 rounded">
                        自动模式
                    </span>
                    下，静音2秒自动发送语音。该模式适用于安静的环境。
                    <br/>
                    话筒图标被划掉时
                        <button className="mic-off">
                            <span className="icon-mic_off"/>
                        </button>
                    ，录音功能暂时不可用。
                </div>
            </div>;
        }
        return talkyMessages.map((conversation, index) => {
            return <div className="topics" key={index}>
                { index ?  <div className="new-topic"></div> : null }
                {conversation.map((reply, index) => {
                    return <div className="talky-ask-reply" key={index}>
                        <div
                            className="talky-line ask"
                            onClick={() => {
                                playAudioMe(reply.audioMe);
                            }}
                        >
                        <div className="avatar"><i className="icon-ask"/>
                        </div>
                        <div className="contents">
                            {reply.prompt ? reply.prompt : loadingDots()}
                        </div>
                    </div>
                    <div
                        className="talky-line reply"
                        onClick={() => {
                            playResponseClicked(reply);
                        }}
                    >
                        <div className="contents">
                            {formatResponse(reply)}
                        </div>
                        <div className="avatar">
                            <i className="icon-reply"/>
                        </div>
                    </div></div>;
                })}
            </div>;
        });
    };

    const closeTalkyPanel = () => {
        closeTalky();
    };

    function displayInstruction()
    {
        const startWithInstruction = async () => {
            if (talkyMessages.at(-1)?.length) {
                talkyMessages.push([]);
            }
            setInstructionOn(false);
            if (!instruction) {
                return;
            }
            setDisableTalkyControl(true);
            const ret = await talkFactory.current.talk({
                powerOff: async () => {
                    console.log('powered off in instruction talk');
                    setDisableTalkyControl(false);
                    setPauseTalking(false);
                    setTalking(false);
                    setTalkyReady(false);
                    setPower(false);
                    setTalkingInProgress(false);
                },
                conversation: talkyMessages.at(-1),
                instruction: instruction,
                speed: speed
            });
            talkCallbacks.current = ret;
        };

        const instructionChanged = (e) => {
            setInstruction(e.target.value);
        };
        return <div className="instruction-panel">
            <div className="instruction-input">
                <textarea
                    placeholder="输入指令"
                    rows="5"
                    className="form-control"
                    type="text"
                    value={instruction}
                    onChange={instructionChanged}
                />
                <div className="instruction-buttons">
                    <button
                        className="btn btn-primary"
                        onClick={startWithInstruction}
                    >开始一个新话题</button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => {
                            setInstruction('');
                        }}
                    >清空</button>
                    <button
                        className="btn btn-warning"
                        onClick={() => {
                            setInstructionOn(false);
                        }}
                    >取消</button>
                </div>
            </div>
        </div>
    }

    return <div
        className={`talky-panel
            ${talkyReady ? 'talky-ready' : ''}`
        }
    >
        {cancelButton}
        <PlayBack
            text2Voice={text2Voice}
            synthesizing={false}
            onClose={() => {
                setText2Voice(false);
            }}
        />
        <audio ref={audioRef} style={{display: 'none'}} />
        <div className="talky-panel-title">
            <div className="title">语音对话</div>
            <button
                className={`new-topic-button lighting-button
                    ${instructionOn ? ' on' : ''}`}
                disabled={pauseTalking || talking}
                onClick={() => setInstructionOn(!instructionOn)}
            >
            设置指令
            </button>
            <span className="icon-clear" onClick={closeTalkyPanel} />
        </div>
        {instructionOn ? displayInstruction() : null}
        <div
            className={`talky-messages`
                + `${talkingInProgress ? ' in-progress' : ''}`}
            ref={messagesRef}
        >
            {displayTalkyMessages()}
        </div>
        <div className="talky-control">
            <div className="side-controls">
                <button
                    disabled={pauseTalking || talking}
                    className="btn btn-warning"
                    onClick={() => {
                        setSpeed(prev => {
                            return prev === 'slow' ? 'normal' : 'slow';
                        });
                    }}
                >
                    {SPEED[speed]}
                </button>
                <button
                    className="btn btn-warning"
                    onClick={() => {
                        setTranscriptSwitch(prev => !prev);
                    }}
                >
                    {transcriptSwitch ? '字幕开' : '字幕关'}
                </button>
            </div>
            <div className="push-button">
                <button className={`pushable
                    ${talking ? 'blinking-mic' : ''}`}
                    onClick={talkyButtonPressed}
                >
                    <div className="flash-sending">
                        <span
                            className={`icon-wifi_tethering
                                ${talkSending ? 'sending' : ''}`}
                            />
                    </div>
                    <span
                        className={
                            `icon-mic${pauseTalking ? '_off' : '1'}
                                ${talking ? 'blinking-mic' : ''}`
                        }
                    />
                    <div className="power-level" ref={powerLevel}>
                    </div>
                </button>
            </div>
            <div className="side-controls">
                <button
                    className="btn btn-info"
                    disabled={talking}
                    onClick={() => {
                        setGender(prev => 1 - prev);
                    }}
                >
                    { gender ? '男声' : '女声' }
                </button>
                <button
                    className="btn btn-info"
                    disabled={talking}
                    onClick={() => {
                        setAutomatic(prev => !prev);
                    }}
                >
                    { automatic ? '自动模式' : '手动模式' }
                </button>
            </div>
            { disableTalkyControl ?
                <div className="disable-talky-control"/> : null }
        </div>
        { timeLeft ?
            <div className="time-left">{timeLeft.display}秒后停止录音</div>
            : null
        }
    </div>;
}

export default TalkyPanel;
