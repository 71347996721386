import React, { useEffect, useState, memo } from 'react';
import Typewriter from './TypeWriter.js';
import { Utils, WebSocket, accessCode } from './bpdbcrud.js';
import { PlayBack, concatAudioBuffers } from './PlayBack.js';
import Image from './Image';

const CompletionComponent = memo(({
    prevText = '', completion = '', speed = 0, blinking = false, isLoading,
    isError = false, member
}) => {
    const role = member.role;
    const [copied, setCopied] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [text2Voice, setText2Voice] = useState(false);
    const [synthesizing, setSynthesizing] = useState(false);

    if (isLoading) {
        return (
            <div className={`history-container history-${role} history-shadow`} >
                <div className="history-left"/>
                <div className="history-content">
                    <div className="loading-dots">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="history-right"/>
            </div>
        );
    }

    function copiedSuccessfully()
    {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    }

    function text2speech(item, speed)
    {
        const arrayBuffers = [];
        const socket = WebSocket.create(
            () => {
                setSynthesizing(true);
                socket.on('tts', (audioContent) => {
                    arrayBuffers.push(audioContent);
                });
                socket.emit(
                    'tts',
                    accessCode.readAccessCode(),
                    speed,
                    item.id,
                    item.role,
                    item.content
                );
            },
            async () => {
                setSynthesizing(false);
                if (arrayBuffers.length === 0) {
                    Utils.message(`语音转换失败`, `请稍后重试`);
                    item.hearing = false;
                    setText2Voice(false);
                    return;
                }

                const blob = await concatAudioBuffers(arrayBuffers);
                if (!blob) {
                    setText2Voice(false);
                    return;
                }
                item.voice = window.URL.createObjectURL(blob)
                /**
                 * Should revoke the object url if the page is refreshed with
                 * new contents.
                 */
                setText2Voice(item);
            },
            (err) => {
                Utils.tipOnTop(`网络连接错误：${JSON.stringify(err)}`);
                item.hearing = false;
                setSynthesizing(false);
                setText2Voice(false);
            },
            (err) => {
                Utils.tipOnTop(`语音转换错误：${JSON.stringify(err)}`);
                item.hearing = false;
                setSynthesizing(false);
                setText2Voice(false);
            }
        );
    }

    const toggleHearing = () => {
        member.hearing = true;
        if (member.voice) {
            setText2Voice(member);
        } else {
            /**
             * Let the playback panel display immediately.
             */
            setText2Voice({content: true});
            text2speech(member, 'normal');
        }
    };

    function newline(str)
    {
        const arr = [];
        const lines = str?.split(/([\n\r])/);
        lines?.forEach((l, j) =>  {
            arr.push(
                l === '\n' || l === '\r' ? <br key={`${j}`} /> : l
            );
        });
        return arr;
    }

    const displayContent = () => {
        return <>
            <Typewriter
                role={role}
                speed={speed}
                prevText={isError ? '' : prevText}
                text={isError ? prevText : completion}
                blinking={isError ? false : blinking}
            />
            {
                isError ?
                    <div className="error-message">{newline(completion)}</div>
                    :
                    null
            }
        </>;
    };

    const displayVisionImages = (member) => {
        const list = [];
        if (member.visionImages) {
            let i = 0;
            for (const image of member.visionImages) {
                const iu = URL.createObjectURL(image);
                list.push(<Image
                    maxWidth="200px"
                    maxHeight="200px"
                    src={iu}
                    key={i++}
                />);
            }
        }
        if (member.images) {
            for (let i = 0; i < parseInt(member.images); i++) {
                const h = Utils.getHost();
                const img = h ? <Image
                    key={i}
                    src={`http://${h}/gi/${member.id}/${i}`}
                    maxWidth="200px"
                    maxHeight="200px"
                /> : <Image
                    src={`gi/${member.id}/${i}`} key={i}
                    maxWidth="200px"
                    maxHeight="200px"
                />;
                list.push(img);
            }
        }
        if (list.length) {
            return <div className="vision-frame">
                <div className="vision-images">{list}</div>
            </div>;
        }
        return null;
    };

    const historyLeft = <div className="history-left">
        <div
            className={
                `message-selection ${!member.content ? 'd-none' : ''}`
            }
            onClick={ () => {
                member.toggleSelection();
            }}
        >
            <span
                className={
                    `icon-${role === 'user' ? 'ask' : 'reply'}`
                }>
            </span>
            <span
                className={
                    member.selected ?
                        "icon-check_box" : "icon-format_quote"
                }
            />
        </div>
    </div>;

    const historyRight = <div
        className={
            `history-right ${member.content ? '' : 'd-none'}`
        }
    >
        <div
            className={`clipboard ${copied ? "copied" : "copy"}`}
            onClick={ () =>
                Utils.copyToClipboard(
                    isError ? prevText : prevText + completion,
                    copiedSuccessfully
                )
            }
        >
            <span className={
                copied ? "icon-check" : "icon-content_copy"
            } />
        </div>
        <div
            className={`hearing`
                + `${member.audio || member.voice ?
                    ' has-audio' : '' }`
                + `${member.hearing ? ' active' : ''}`
            }
            onClick={toggleHearing}
        >
            <span
                className='icon-hearing'
            />
        </div>
    </div>;

    function displayTime()
    {
        if (!member.time) {
            return null;
        }
        const d = new Date(member.time);
        const y = d.getFullYear(), m = d.getMonth() + 1, t = d.getDate(),
            h = d.getHours(), i = d.getMinutes(), s = d.getSeconds();
        function p(g)
        {
            return ('' + g).padStart(2, '0');
        }

        return `${y}-${p(m)}-${p(t)} ${p(h)}:${p(i)}:${p(s)}`;
    }

    return <>
        <PlayBack
            text2Voice={text2Voice}
            synthesizing={synthesizing}
            onClose={() => {
                text2Voice.hearing = false;
                setText2Voice(false);
            }}
        />
        <div className={`history-container history-${role} history-shadow`}>
            {historyLeft}
            <div className="history-content">
                <div className="datetime">{displayTime()}</div>
                <div style={{position: 'relative', zIndex: 200}}>
                    {displayContent()}
                </div>
                {displayVisionImages(member)}
            </div>
            {historyRight}
        </div>
    </>;
});

export default CompletionComponent;

