import React, { useState } from 'react';
import './Image.css';

export default function Image({src, maxWidth = '100%', maxHeight = '100%'})
{
    const [full, setFull] = useState(false);
    const [showFull, setShowFull] = useState(false);

    if (full) {
        return <div className="image-Image full-screen">
            <div className="close-full-screen"
                onClick={() => {
                    setFull(false);
                }}
            ><span className="icon-clear"/></div>
            <img src={src} />
        </div>;
    }

    return <div
        className="image-Image"
        onClick={() => setShowFull(!showFull)}
    >
        <div
            className="full-screen-button"
            style={{
                display: showFull ? '' : 'none'
            }}
            onClick={() => {
                setFull(true);
            }}
        >
            <span className="bpdbcrud-open_in_full"/>
        </div>
        <img
            style={{
                maxWidth: maxWidth,
                maxHeight: maxHeight,
            }}
            src={src}
        />
    </div>;
}


