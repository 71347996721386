import React, { useEffect, useState, useRef, useContext } from 'react';
import ChatGPT from './ChatGPT';
import Painter from './Painter.js';
import Nav from './Nav';
import {Utils, WebSocket, Login, accessCode} from './bpdbcrud.js';

function Entry()
{
    window.document.title = 'AI assistant, Blue Pyxis';
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const [currentPanel, setCurrentPanel] = useState('ChatGPT');
    const [invalidSession, setInvalidSession] = useState(false);

    const historyResult = useRef(false);
    const updateHistory = (history) => {
        historyResult.current = history;
    };

    /*
    const sessionCheckingId = useRef();
    if (sessionCheckingId.current) {
        clearInterval(sessionCheckingId.current);
    }
    if (loggedIn) {
        sessionCheckingId.current = setInterval(async () => {
            if (!invalidSession && !await accessCode.checkAccessCode()) {
                setInvalidSession(true);
            }
        }, 30000);
    }
    */

    useEffect(() => {
        (async () => {
            setLoggedIn(await accessCode.checkAccessCode());
            setLoading(false);
        })();
    }, []);

    const painterRef = useRef();
    const firstTime = useRef(true);
    useEffect(() => {
        if (firstTime.current && currentPanel === 'Painter') {
            if (painterRef.current) {
                const ref = painterRef.current;
                ref.scrollTop = ref.scrollHeight;
                firstTime.current = false;
            }
        }
    }, [currentPanel]);

    if (invalidSession) {
        return <div
            style={{
                width: '100vw',
                height: '100vh',
                backgroundColor: 'purple',
                display: 'flex',
                position: 'fixed',
                top: 0,
                left: 0,
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                cursor: 'pointer'
            }}
            onClick={() => {
                window.location.reload();
            }}
        >
            <h1>登录已失效</h1>
        </div>;
    }

    const chatgpt = <ChatGPT
        historyResult={historyResult.current}
        updateHistory={updateHistory}
        onInvalidSession={() => {
            setInvalidSession(true);
        }}
    />;

    const painter = <Painter ref={painterRef} />;

    return (<>
        {
            loading ? <div className="app-loading"/> : (loggedIn ?
                <div className="App">
                    <header className="App-header">
                        <Nav
                            currentPanel={currentPanel}
                            setCurrentPanel={setCurrentPanel}
                        />
                    </header>
                    <main className="App-main">
                        <div style={{
                            display: currentPanel === 'ChatGPT' ? '' : 'none'
                        }}>{chatgpt}</div>
                        <div style={{
                            display: currentPanel === 'Painter' ? '' : 'none'
                        }}>{painter}</div>
                    </main>
                </div>
                :
                <Login
                    callback={setLoggedIn}
                />)
        }
    </>);
}

export default Entry;

