class Snapshot
{
    constructor()
    {
    }

    async start(container)
    {
        this.video = null;
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'environment'
                }
            })
            /* Dynamically creating video element and appended to DOM won't
             * play the streaming video in wechat, but works fine for all
             * others including mobile browsers such as firefox and chrome at
             * least on iOS.
            const video = document.createElement('video');
            video.setAttribute('playsinline', '');
            video.setAttribute('autoplay', '');
            video.setAttribute('muted', '');
            container.appendChild(video);
            */
            const video = container.querySelector('video');
            video.onloadedmetadata = async (e) => {
                try {
                    await video.play();
                    this.video = video;
                } catch (err) {
                    alert(err);
                    console.error("Error starting video play: ", err);
                }
            };
            video.srcObject = stream;
            this.video = video;
        } catch(err) {
            alert(err.message);
            console.log(err.name + ": " + err.message);
        } finally {
            return this.video;
        }
    }

    async stop()
    {
        const stream = this.video?.srcObject;
        if (stream) {
            for (const track of stream.getTracks()) {
                track.stop();
            }
            this.video.srcObject = null;
        }
    }

    async shot()
    {
        const canvas = document.createElement('canvas');
        canvas.width = this.video.videoWidth;
        canvas.height = this.video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(this.video, 0, 0, canvas.width, canvas.height);
        return await new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg', 1);
        });
    }
}

class TakePicture
{
    static createFileSelector(setImage)
    {
        const fsid = 'fileselectorid';
        let input = document.getElementById(fsid);
        /**
         * Must remove the input if it already exists, or `onchange` event will
         * not be triggered for the same file on chrome. Firefox does not have
         * such situation.
         */
        if (input) {
            input.remove();
        }
        input = document.createElement('input');
        input.type = 'file';
        input.id = fsid;
        //input.capture = 'camera';
        //input.capture = 'environment';
        //input.accept = 'image/*';
        input.accept = '.jpg, .png, .jpeg';
        input.style.display = 'none';
        /**
         * An file input must be appended to the document, or mobile
         * browsers won't work.
         */
        document.body.appendChild(input);

        /**
         * alert() does not work in an event handler on wechat
         */
        input.onchange = (e) => {
            const file = e.target?.files?.[0];
            setImage(file);
        }

        input.click();

        return input;
    }
}

export {TakePicture};
export default Snapshot;

