import {Utils, WebSocket, accessCode} from './bpdbcrud.js';
import { concatAudioBuffers } from './PlayBack.js';

function createTalk({
    automatic, gender, audio,
    pauseTalking, onReady, onSending, onMessage, onPowerLevel
})
{
    /**
     * The max slots in audio buffers. The number of slots that a piece of
     * voice will fill in is based on the encoded contents of recording by the
     * bitrate, the number of channels, the sample rate and such things that
     * will affect the quality of the encoded recording.
     */
    const MAX_RECORDING_CONTENTS = 600;

    const audioContext =
        new (window.AudioContext || window.webkitAudioContext)();

    let voiceGender = gender;
    let voiceAuto = automatic;

    async function talk({
        powerOff, conversation, instruction, speed
    })
    {
        const Recorder = window.Recorder;
        const MAX_SILENCE = 2000;
        let voiceRecorder;
        let isClosed = false, sending = false, isMuted = false;
        let recorderStopped = false, socketClosed = false;
        const silence = { start: -1 };
        let talkContent = {
            prompt: null,
            audioMe: null,
            response: '',
            audios: [],
            audioBuffers: [],
            currentPlaying: 0,
            done: false
        };

        function checkFinished()
        {
            if (socketClosed) {
                onSending(false);
                if (!talkContent.audioBuffers?.length) {
                    talkContent.done = true;
                }
                if (talkContent.done && !talkContent.playingNow) {
                    powerOff();
                    audio.onended = null;
                    audio.oncanplaythrough = null;
                    audio.onerror = null;
                }
            }
        }

        function shutdownRecorder()
        {
            if (voiceRecorder?.rec) {
                /**
                 * An exception will be thrown if rec.close() is called from
                 * the talky panel's destruction callback and the talky button
                 * is on.
                 */
                try {
                    voiceRecorder.rec.close();
                } catch (error) {
                    console.log(error, 'KNOWN error');
                    Recorder.Destroy();
                }
            } else if (Recorder.IsOpen()) {
                Recorder.Destroy();
            }
        }

        function shouldCloseSocket()
        {
            return !sending && isClosed &&
                (talkContent.done || !talkContent.audioBuffers?.length);
        }

        let prevBufferIdx = 0;
        const onProcess = async (buffers, powerLevel, bufferDuration,
               bufferSampleRate, newBufferIdx) => {
            if (isMuted) {
                return;
            }
            try {
                if (silence.start < 0) {
                    silence.start = Date.now();
                }
                if (powerLevel > 1) {
                    onPowerLevel(powerLevel);
                    silence.hasVoice = true;
                    silence.start = Date.now();
                }
                //if (voiceAuto) {
                    for (let i = prevBufferIdx; i < newBufferIdx; i++) {
                        buffers[i] = null;
                    }
                    prevBufferIdx = newBufferIdx;
                //}
            } catch (error) {
                console.log(error);
            }
        };

        let audioBuffer = [];
        let socket;
        async function realTimeTakeoff(chunkBytes, sendImmediately)
        {
            if (chunkBytes && silence.hasVoice) {
                /**
                 * 10 slots of audioBuffer is roughly 1 second recording.
                 */
                audioBuffer.push(chunkBytes);
            }
            silence.hasVoice = false;
            const now = Date.now();
            if (sendImmediately || (voiceAuto && silence.start > 0 &&
                 now - silence.start > MAX_SILENCE)
                     || audioBuffer.length > MAX_RECORDING_CONTENTS
            ) {
                silence.start = -1;
                let len = 0;
                for (const b of audioBuffer) {
                    len += b.length;
                }
                const chunkData = new Uint8Array(len);
                let idx = 0;
                for (const b of audioBuffer) {
                    chunkData.set(b, idx);
                    idx += b.length;
                }
                if (chunkData.length > 0) {
                    const blob = new Blob([chunkData], {type:"audio/mp3"});
                    /*
                    if (await (() => {
                        return new Promise((resolve) => {
                            const t = new Audio();
                            t.onloadedmetadata = () => {
                                console.log(t.duration);
                                window.URL.revokeObjectURL(t.src);
                                resolve(t.duration > 0.5);
                            };
                            t.src = window.URL.createObjectURL(blob);
                            t.load();
                        });
                    })()) {
                    */
                    audioBuffer = [];
                    uploadRecording(blob);
                } else if (shouldCloseSocket()) {
                    socket.disconnect(true);
                }
            }
        }

        function enableSpeech()
        {
            if (recorderStopped) {
                return true;
            }
            shutdownRecorder();
            return new Promise((resolve, reject) => {
                voiceRecorder = {
                    rec: null,
                    wave: null,
                };
                voiceRecorder.rec = Recorder({
                    type: 'mp3',
                    sampleRate: 48000,
                    bitRate: 128,
                    /* useless for audio volume */
                    audioTrackSet:{
                        echoCancellation: true,
                        noiseSuppression: true
                    },
                    onProcess: (
                            buffers, powerLevel, bufferDuration,
                            bufferSampleRate, newBufferIdx) => {
                        onProcess(buffers, powerLevel, bufferDuration,
                            bufferSampleRate, newBufferIdx);
                    },
                    /*
                    takeoffEncodeChunk: voiceAuto ? (chunkBytes) => {
                        realTimeTakeoff(chunkBytes, false);
                    } : null
                    */
                    takeoffEncodeChunk: (chunkBytes) => {
                        realTimeTakeoff(chunkBytes, false);
                    }
                });
    /*
        MP3的采样频率分为 48000 44100 32000 24000 22050 16000 12050 8000

        比特率值与现实音频对照（仅供参考）
    　　16Kbps=电话音质
    　　24Kbps=增加电话音质、短波广播、长波广播、欧洲制式中波广播
    　　40Kbps=美国制式中波广播
    　　56Kbps=话音
    　　64Kbps=增加话音（手机铃声最佳比特率设定值、手机单声道MP3播放器最佳设定值）
    　　112Kbps=FM调频立体声广播
    　　128Kbps=磁带（手机立体声MP3播放器最佳设定值、低档MP3播放器最佳设定值）
    　　160Kbps=HIFI高保真（中高档MP3播放器最佳设定值） 　
      　192Kbps=CD（高档MP3播放器最佳设定值）
    　　256Kbps=Studio音乐工作室（音乐发烧友适用）
    */

    //唯一影响mp3文件大小的参数为 bitRate
    //sampleRate 仅供特殊需求的人使用
                if (!Recorder.IsOpen()) {
                    voiceRecorder.rec.open(() => {
                        resolve(false);
                    }, (error, userNotAllowed) => {
                        if (userNotAllowed) {
                            Utils.message('未获授权', '请确认录音权限');
                        } else {
                            Utils.message('错误', error);
                        }
                        resolve(true);
                    });
                } else {
                    resolve(false);
                }
            });
        }

        async function startRecording()
        {
            if (await enableSpeech()) {
                return true;
            }

            if (!(voiceRecorder.rec && Recorder.IsOpen())) {
                Utils.message('录音失败', '请确认录音权限');
                return true;
            }

            voiceRecorder.rec.start();

            silence.hasVoice = false;

            return false;
        }

        const stopRecording = () => {
            //if (voiceAuto) {
                realTimeTakeoff(null, true);
                shutdownRecorder();
                /*
            } else {
                if (voiceRecorder?.rec) {
                    voiceRecorder.rec.stop((blob, duration) => {
                        shutdownRecorder();
                        voiceRecorder.rec = null;
                        if (silence.hasVoice) {
                            uploadRecording(blob);
                        } else if (shouldCloseSocket()) {
                            socket.disconnect(true);
                        }
                    }, (msg) => {
                        shutdownRecorder();
                        voiceRecorder.rec = null;
                        if (shouldCloseSocket()) {
                            socket.disconnect(true);
                        }
                        console.log('Stop recording failed', msg);
                        //Utils.message('上传语音错误', msg);
                    }, false);
                }
            }
            */
            recorderStopped = true;
            silence.start = -1;
            isClosed = true;
        };

        const cancelRecording = () => {
            if (voiceRecorder?.rec) {
                voiceRecorder.rec.stop();
                shutdownRecorder();
                voiceRecorder.rec = null;
            }
            recorderStopped = true;
            silence.start = -1;
            isClosed = true;
            /**
             * Set socketClosed to true to prevent calling stopRecording in
             * the socket's onDisconnect event handler.
             */
            socketClosed = true;
            socket.disconnect(true);
            onSending(false);
            talkContent.done = true;
            powerOff();
            audio.onended = null;
            audio.oncanplaythrough = null;
            audio.onerror = null;
        };

        function muteMic()
        {
            isMuted = true;
            pauseTalking(true);
            shutdownRecorder();
        }

        async function unmuteMic()
        {
            if (!Recorder.IsOpen()) {
                if (await startRecording()) {
                    return;
                }
            }
            isMuted = false;
            pauseTalking(false);
            silence.start = -1;
        }

        const blobQueue = [];
        let blobQueueIdx = 0;
        /**
         * The recorded pieces of voices will be sent in an ordered sequence by
         * their recording time.
         */
        const uploadRecording = async (blob) => {
            blobQueue.push(blob);
            talkContent.done = false;
            if (sending) {
                return;
            }
            sending = true;
            onSending(true);
            /**
             * Actually, only one blob should exist, because when sending
             * starts, microphone should be muted.
             */
            while (blobQueue.length - blobQueueIdx >= 1) {
                const b = blobQueue[blobQueueIdx];
                muteMic();
                conversation.push({
                    audioMe: window.URL.createObjectURL(b)
                });
                onMessage(conversation);
                const reply = await send({
                    audioMe: blobQueue[blobQueueIdx]
                });
                blobQueue[blobQueueIdx++] = null;
                if (reply.error) {
                    break;
                }
            }
            if (shouldCloseSocket()) {
                socket.disconnect(true);
            }
        };

        const playResponseAudio = (talkContent) => {
            if (talkContent.playingNow) {
                return;
            }
            talkContent.playingNow = true;
            muteMic();

            function canplaythrough()
            {
                if (audio.terminated) {
                    return;
                }
                audio.volume = 1;
                audio.play().catch((error) => {
                    console.log(error);
                    //Utils.message('错误', '我无法用语音回答');
                });
            }

            function playing()
            {
                if (audio.terminated) {
                    audio.onended = null;
                    audio.canplaythrough = null;
                    audio.pause();
                }
            }

            function ended()
            {
                talkContent.currentPlaying++;
                //window.URL.revokeObjectURL(audio.src);
                realPlayResponseAudio(audio, talkContent);
            }

            function error(e)
            {
                console.log(e);
                talkContent.currentPlaying = talkContent.audioBuffers.length;
                talkContent.playingNow = false;
                checkFinished();
                unmuteMic();
                Utils.message('错误', '我无法用语音回答');
            }

            /*
             * addEventListener does not work properly, even if using
             * removeEventListener.
            audio.addEventListener('error', error, { once: true });
            audio.addEventListener(
                'canplaythrough', canplaythrough, { once: true }
            );
            audio.addEventListener(
                'ended', ended, { once: true }
            );
            */
            audio.onended = ended;
            audio.oncanplaythrough = canplaythrough;
            audio.onerror = error;
            audio.onplaying = playing;

            realPlayResponseAudio(audio, talkContent);
        };

        function realPlayResponseAudio(audio, tc)
        {
            if (tc.currentPlaying < tc.audioBuffers?.length) {
                /*
                const chunk = tc.audioBuffers[tc.currentPlaying];
                audioContext.decodeAudioData(chunk, (buffer) => {
                    const source = audioContext.createBufferSource();
                    source.buffer = buffer;
                    source.connect(audioContext.destination);
                    source.onended = () => {
                        realPlayResponseAudio(audio, tc);
                    };
                    source.start(0);
                });
                */
                audio.src = tc.audios[tc.currentPlaying];
                audio.load();
            } else {
                tc.playingNow = false;
                if (tc.done) {
                    unmuteMic();
                    checkFinished();
                }
            }
        }

        /*
        function sendNow()
        {
            console.log('sending now');
            realTimeTakeoff(null, true);
        }
        */

        let resolveTalk;
        function send({audioMe, text})
        {
            talkContent = {
                response: '',
                audios: [],
                audioBuffers: [],
                currentPlaying: 0,
                done: false
            };
            return new Promise((resolve) => {
                let previously = [], totalLen = 0;
                /**
                 * The last 2 elements of conversation has no prompt nor
                 * response. Therefore, the largest previously can have
                 * 6 elements.
                 */
                for (const c of conversation.slice(-4)) {
                    if (c.prompt) {
                        totalLen += c.prompt.length;
                        previously.push({
                            role: 'user',
                            content: c.prompt
                        });
                    }
                    if (c.response) {
                        totalLen += c.response.length;
                        previously.push({
                            role: 'assistant',
                            content: c.response
                        });
                    }
                    while (totalLen > 2000 && previously.length > 2) {
                        const removed = previously.shift();
                        totalLen -= removed.content.length;
                    }
                }
                resolveTalk = resolve;
                if (instruction) {
                    if (previously.length === 1) {
                        previously = [];
                    } else if (instruction !== previously[0]?.content) {
                        previously[0] = {
                            role: 'user',
                            content: instruction
                        };
                    }
                }
                console.log(previously);
                socket.emit(
                    'talk',
                    accessCode.readAccessCode(),
                    {
                        voice: audioMe,
                        previously: previously,
                        gender: voiceGender,
                        speed: speed,
                        text: text
                    }
                );
            });
        }

        const onTalk = async (reply, resolve) => {
            /**
             * reply.error can never be true, the bridge will not respond a
             * reply whose error is true.
             */
            if (reply.error) {
                conversation.pop();
                sending = false;
                /**
                 * socket.disconnect will trigger the socket's disconnect
                 * event, which sets socketClosed to true and calls
                 * checkFinished() where onSending(false) will be called
                 * because socketClosed is true.
                 */
                socket.disconnect(true);
                resolve(reply);
                return;
            }
            if (reply.empty) {
                onSending(false);
                sending = false;
                unmuteMic();
                conversation.pop();
                onMessage(conversation);
                resolve(reply);
                return;
            }

            if (reply.prompt) {
                talkContent.prompt = reply.prompt;
            }
            if (reply.response) {
                talkContent.response += reply.response;
            }
            if (reply.audio) {
                talkContent.audioBuffers.push(reply.audio);
                const blob = new Blob(
                    [reply.audio], { type: "audio/mp3" }
                );
                if (blob.size > 0) {
                    talkContent.audios.push(window.URL.createObjectURL(blob));
                }
                playResponseAudio(talkContent);
            }
            const cl = conversation.length - 1;
            talkContent.audioMe = conversation[cl].audioMe;
            conversation[cl] = talkContent;
            onMessage(conversation);
            if (reply.done) {
                onSending(false);
                sending = false;
                talkContent.done = true;
                if (!talkContent.playingNow && talkContent.currentPlaying
                    >= talkContent.audioBuffers.length)
                {
                    unmuteMic();
                    checkFinished();
                }
                if (shouldCloseSocket()) {
                    socket.disconnect(true);
                }
                const blob =
                    await concatAudioBuffers(talkContent.audioBuffers);
                if (blob) {
                    talkContent.playbackAudio =
                        window.URL.createObjectURL(blob);
                }
                resolve({error: false});
            }
        };

        const createSocket = () => {
            function onError(title, err)
            {
                console.log(title, err);
                conversation.pop();
                onMessage(conversation);
                stopRecording();
                sending = false;
                /**
                 * Inside checkFinished(), onSending(false) will be called
                 * because socketClosed is true.
                 * And setting socketClosed to true before disconnecting the
                 * socket will let the disconnect event handler not call
                 * checkFinished() and stopRecording() again.
                 */
                socketClosed = true;
                checkFinished();
                socket.disconnect();
                //Utils.message(title, JSON.stringify(err));
                Utils.tipOnTop(`${title}: ${JSON.stringify(err)}`);
            }

            return new Promise((resolve) => {
                const socket = WebSocket.create(
                    () => {
                        isClosed = false;
                        socket.on('talk', (reply) => {
                            onTalk(reply, resolveTalk);
                        });
                        resolve(socket);
                    },
                    () => {
                        /**
                         * onError() will set socketClosed to true, and call
                         * checkFinished() and stopRecording().
                         * So if you don't use onError, you have to process
                         * these steps here.
                         */
                        if (!socketClosed) {
                            socketClosed = true;
                            stopRecording();
                            checkFinished();
                        }
                        console.log('disconnected for talking');
                        resolve(socket);
                    },
                    (err) => {
                        onError('连接错误', err);
                        resolve(socket);
                    },
                    (err) => {
                        onError('传输错误', err);
                        resolve(socket);
                    }
                );
            });
        };

        socket = await createSocket();

        if (instruction && !conversation.length) {
            onReady(false);
            recorderStopped = true;
            conversation.push({
                prompt: instruction
            });
            onMessage(conversation);
            isClosed = true;
            await send({text: instruction});
        } else {
            if (await startRecording()) {
                onReady(false);
                return false;
            }
            onReady(true);
        }

        return {
            stopTalking: stopRecording,
            cancelTalking: cancelRecording,
            playResponseAudio: playResponseAudio,
        };

    }

    return {
        talk: talk,
        setAutomatic: automatic => voiceAuto = automatic,
        setGender: gender => voiceGender = gender
    };
}

export default createTalk;
