import React, { useEffect, useState, useRef } from 'react';
import {
    Utils, WebSocket, DataEditor, DataEditorRow, Overlay, Table, Spinner,
    saveDoc, getDataType, query, fetch, JSONEditor
} from './bpdbcrud.js';
import Instruction from './Instruction.js';

function InstructionList()
{
    const [instructionMap, setInstructionMap] = useState(false);
    const [selectedInstruction, setSelectedInstruction] = useState(false);
    const [editable, setEditable] = useState(false);

    const changed = useRef(false);
    const saveButton = useRef();

    function setChanged(v)
    {
        changed.current = v;
        if (saveButton.current) {
            if (v) {
                saveButton.current.style.display = 'block';
            } else {
                saveButton.current.style.display = 'none';
            }
        }
    }

    useEffect(() => {
        setChanged(changed.current);
    });

    useEffect(() => {
        Instruction.getInstructions(setInstructionMap);
    }, []);

    const save = async () => {
        const si = selectedInstruction;
        /*
        const ret = await saveDoc({
            dtName: 'instruction',
            id: si.id,
            values: {
                name: si.name,
                config: JSON.stringify(si.config)
            },
            version: si.meta?.version
        });
        if (ret.error) {
            console.log(ret);
            Utils.message('保存出错', ret.code);
            return;
        }
        */
        const doc = JSON.stringify({
            name: si.name,
            config: JSON.stringify(si.config)
        });
        const para = {
            security_level: 0,
            catalog: '/data',
            dt_name: 'instruction',
            form_data: doc
        };
        if (si.id) {
            para.id = si.id;
        }
        if (si.meta?.version) {
            para.version = si.meta.version;
        }
        const usp = new URLSearchParams(para);
        const h = Utils.getHost();
        const url = h ? `http://${h}/saveinstruction` : `/saveinstruction`;
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: usp
        });
        if (response.ok) {
            const data = await response.json();
            if (data.error) {
                console.log(data);
                Utils.tipOnTop('保存指令错误: ' + JSON.stringify(data));
                return;
            }
        } else {
            Utils.tipOnTop('保存指令错误: ' + response.statusText);
            return;
        }
        setSelectedInstruction(false);
        setChanged(false);
        setEditable(false);
        setInstructionMap(false);
        Instruction.reloadInstructions(setInstructionMap);
    };

    function InstructionName()
    {
        /**
         * Do not use editable directly, or the whole page will be rerendered
         * when the input loses focus, so that clicking on other elements will
         * fail to proceed.
         */
        const [active, setActive] = useState(editable);
        const inputRef = useRef();
        useEffect(() => {
            if (active && inputRef.current) {
                inputRef.current.focus();
            }
        });
        return <div className="instruction-name">
            <div>
                <button
                    className={active ? 'hidden' : ''}
                    onClick={() => {
                        setActive(true);
                    }}
                ></button>
                <input
                    ref={inputRef}
                    placeholder="名称"
                    defaultValue={selectedInstruction.name}
                    disabled={!active}
                    onBlur={(e) => setActive(false)}
                    onChange={(e) => {
                        selectedInstruction.name = e.target.value;
                        setChanged(true);
                    }}
                />
            </div>
            <button
                ref={saveButton}
                onClick={save}
                className="save-button"
            >
                <span className="bpdbcrud-done"/>
            </button>
        </div>;
    }

    function InstructionId()
    {
        const [copied, setCopied] = useState(false);
        if (!selectedInstruction.id) {
            return;
        }
        return <div className="instruction-id">
            ID: {selectedInstruction.id}
            <button
                ref={saveButton}
                onClick={() => Utils.copyToClipboard(
                    selectedInstruction.id,
                    () => {
                        setCopied(true);
                        setTimeout(() => {
                            setCopied(false);
                        }, 5000);
                    }
                )}
                className="copy-button"
            >
                <span className={`icon-${copied ? 'check' : 'content_copy'}`}/>
            </button>
        </div>;
    }

    const configUpdated = (config) => {
        selectedInstruction.config = config;
        setChanged(true);
    };

    const confirm = (further) => {
        if (changed.current) {
            Utils.confirm({
                messages: [
                    '数据未保存', '按“确定”会丢失修改的数据'
                ],
                furtherFunc: () => {
                    setChanged(false);
                    setEditable(false);
                    further();
                }
            });
        } else {
            setChanged(false);
            setEditable(false);
            further();
        }
    };

    function list()
    {
        return instructionMap ? <div className="instruction-map">
            {[...instructionMap.values()].map(
                (instr, i) => <button key={i}
                    disabled={selectedInstruction.id === instr.id}
                    className={
                        selectedInstruction.id === instr.id ? 'selected' : ''
                    }
                    onClick={() => {
                        if (!changed.current) {
                            setEditable(false);
                            setSelectedInstruction(instr);
                            return;
                        }
                        confirm(() => setSelectedInstruction(instr));
                    }}
                >
                    {instr.name}
                </button>
            )}</div> : <Spinner size="3rem" color="orange"/>;
    }

    return <div className="instruction-list">
        <div className="refresh-add-buttons">
            <button
                onClick={() => {
                    confirm(() => {
                        setSelectedInstruction(false);
                        setInstructionMap(false);
                        Instruction.reloadInstructions(setInstructionMap);
                    });
                }}
            >
                <span className="bpdbcrud-cached"/>
            </button>
            <button
                onClick={() => {
                    setEditable(true);
                    setChanged(true);
                    setSelectedInstruction({});
                }}
            >
                <span className="bpdbcrud-add"/>
            </button>
        </div>
        {list()}
        <div className="instruction-view">
            {selectedInstruction ? <>
                <InstructionId />
                <InstructionName />
                <JSONEditor
                    json={selectedInstruction.config}
                    updated={configUpdated}
                />
            </> : null}
        </div>
    </div>;
}

export default InstructionList;
